import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router/'
import store from "./store/";

Vue.config.devtools = true;
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router, vuetify, store
}).$mount('#app')