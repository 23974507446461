<template>
  <div v-if="currentReferralAdvocate">
    <div class="col-sm-8 col-md-10 col-lg-8 mx-auto">
      <p class="headline"><span v-if="$store.getters.resAgent">View</span><span v-else>Edit</span> Referral Advocate</p>
      <Alert :resp="message" />
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
            <v-text-field v-model="currentReferralAdvocate.user.firstName"
              label="First Name"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
            <v-text-field v-model="currentReferralAdvocate.user.lastName"
              label="Last Name"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
            <v-text-field v-model="currentReferralAdvocate.user_Email"
              label="User Email"
              disabled
            ></v-text-field>
          </v-col>      
        </v-row>

        <v-row>
          <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
            <v-text-field  v-if="currentReferralAdvocate.booking" v-model="currentReferralAdvocate.booking.code"
              label="Latest Booking Code"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
            <v-text-field  v-if="currentReferralAdvocate.booking" v-model="currentReferralAdvocate.booking.sellingCompany_Code"
              label="Latest Booking Selling Company"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
            <v-text-field type="number"
              v-model="currentReferralAdvocate.completedReferrals"
              label="Completed Referrals"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
            <v-text-field
              v-model="currentReferralAdvocate.salesForceContactId"
              label="SalesForce Contact Id"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3" v-if="currentReferralAdvocate.agency != null">
            <v-text-field
              v-model="currentReferralAdvocate.agency.name"
              label="Agency Name"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <BackButton />
            <!--<v-btn color="primary" @click="updateReferralAdvocate">
              Update
            </v-btn>-->
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-divider class="mt-10 mb-5"></v-divider>
    <v-row align="center">
      <v-col cols="12" class="mt-3 pb-0">
        <h3>Credits</h3>
        <Alert :resp="messageRedeem" css="mt-5" />
      </v-col>
      <Search />
      <v-col cols="12" class="pt-0">
        <v-data-table
          :headers="headers"
          :items="ReferralCredits"
          disable-pagination
          class="elevation-2"
          :loading="loadTable"
          loading-text="Loading... Please wait"
          :options.sync="options"
          :server-items-length="count"
          :hide-default-footer="true">

          <template v-slot:[`item.Name`]="{ item }">
            <router-link :to="{ name: 'UpdateReferral', params: { id: item.ReferralId } }">
              {{ item.Name }}
            </router-link>
          </template>

          <template v-slot:[`item.Status`]="{ item }">
            <div class="rounded-pill text-center px-2" :class="item.StatusColor">
              <span class="white--text">{{ item.Status }}</span>
            </div>
          </template>

          <template v-slot:[`item.redeem`]="{ item }">
            <div v-if="!$store.getters.resAgent" class="d-flex">
              <v-icon small class="mr-2" v-if="item.Status == 'Redeemed'" color="success">mdi-check-bold</v-icon>
              <v-tooltip top v-else-if="item.ShowGift">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-2" @click="openDialog(item.Id)" v-bind="attrs" v-on="on">mdi-gift-outline</v-icon>
                </template>
                <span>Click here to redeem all your credits</span>
              </v-tooltip>
              <v-icon small class="mr-2" v-else>mdi-cancel</v-icon>
              <v-icon small class="mr-2" v-if="item.ShowGift" @click="editCredits(item.Id)">mdi-pencil</v-icon>
            </div>
            <div v-else>
              <v-icon small class="mr-2" v-if="item.Status == 'Redeemed' == true">mdi-check-bold</v-icon>
              <v-icon small class="mr-2" v-else>mdi-cancel</v-icon>
            </div>
          </template>
        </v-data-table>
        <Dialog text="Referral Credit (all of them)" verb="redeem" ref="modal" />
      </v-col>
      <Pagination :count="count" :totalPages="totalPages" :pageSize="pageSize" :pageIndex="pageIndex" />
    </v-row>
  </div>

  <div v-else class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <Loading text="Referral Advocate" />
  </div>
</template>

<script>
import ReferralAdvocateService from "../../services/ReferralAdvocateDataService";
import ReferralCreditService from "../../services/ReferralCreditDataService";
import BackButton from "@/components/Common/BackButton.vue";
import Search from '@/components/Common/Search.vue';
import Pagination from '@/components/Common/Pagination.vue';
import Loading from "@/components/Common/Loading.vue";
import Dialog from '@/components/Common/Dialog.vue';
import Alert from '@/components/Common/Alert.vue';
export default {
  name: "ReferralAdvocate",
  data() {
    return {
      currentReferralAdvocate: null,
      message: {
        text: "",
        type: ""
      },
      messageRedeem: {
        text: "",
        type: ""
      },
      ReferralCredits: [],
      headers: [
        { text: "Referral User", value: "Name", sortable: false },
        { text: "Referral Brand", value: "Brand", sortable: false },      
        { text: "Referral Code", value: "ReferralCode", sortable: false }, 
        { text: "Referral Booking", value: "ReferralBookingCode", sortable: false },   
        { text: "Referral SC", value: "ReferralSC", sortable: false },
        { text: "Advocate Booking", value: "AdvocateBookingCode", sortable: false },
        { text: "Advocate SC", value: "AdvocateSC", sortable: false },
        { text: "Amount", value: "Amount" },
        { text: "Redeemed", value: "Redeemed", sortable: false },
        { text: "Created Date", value: "CreatedDate", sortable: false },
        { text: "Redemption Date", value: "RedemptionDate" },
        { text: "Expiration Date", value: "ExpirationDate", sortable: false },
        { text: "Status", value: "Status", sortable: false },
        { text: "Redeem", value: "redeem", sortable: false }
      ],
      query: "",
      pageIndex: 1,
      totalPages: 0,
      pageSize: 10,
      count: 0,
      sortBy: "date_dsc",
      loadTable: true,
      options: {}
    };
  },
  methods: {
    getRequestParams(id, query, pageIndex, pageSize, sortBy) {
      let params = {};
      if (id) {
        params["id"] = id;
      }
       params["query"] = query;
      if (pageIndex) {
        params["pageIndex"] = pageIndex - 1;
      }
      if (pageSize) {
        params["pageSize"] = pageSize;
      }
      if (sortBy) {
        params["sortBy"] = sortBy;
      }
      return params;
    },
    getReferralAdvocate(id) {
      ReferralAdvocateService.get(id)
        .then((response) => {
          this.currentReferralAdvocate = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateReferralAdvocate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == false) return;
      ReferralAdvocateService.update(this.currentReferralAdvocate)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The ReferralAdvocate was updated successfully!";
          this.message.type = "success";
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    retrieveReferralCredits(id) {
      const params = this.getRequestParams(
        id,
        this.query,
        this.pageIndex,
        this.pageSize,
        this.sortBy
      );
      ReferralCreditService.getAllByAdvocateId(params)
      .then((response) => {
        this.ReferralCredits = response.data.data.map(this.getDisplayReferralCredits);
        this.totalPages = Math.ceil(response.data.count/response.data.pageSize,1);
        this.count = response.data.count;
        this.loadTable = false;
      })
      .catch((e) => {
        console.log(e);
      });
    },
    getDisplayReferralCredits(Referral) {
      let statusStyle = {
        'Created': 'blue',
        'Expired': 'grey',
        'Deleted': 'red',
        'Redeemed': 'green',
        'PartiallyRedeemed': 'light-green',
        'Cancelled': 'orange'
      };

      return {
        Id: Referral.referralCredits.id,
        Amount: Referral.referralCredits.amount.toFixed(2),
        Redeemed: Referral.referralCredits.redeemed.toFixed(2),
        RedemptionDate: Referral.referralCredits.redemptionDate,
        CreatedDate: Referral.referralCredits.createdDate,
        ExpirationDate: Referral.referralCredits.expirationDate,
        ReferralId: Referral.id,
        Name: Referral.user.firstName + " " + Referral.user.lastName,
        Brand: Referral.referralBooking.sellingCompany.brand.code,
        ReferralCode: Referral.code,
        ReferralBookingCode: Referral.referralBooking != null ? Referral.referralBooking.code : "",
        AdvocateBookingCode: Referral.referralAdvocate != null ? (Referral.referralAdvocate.booking != null ?  Referral.referralAdvocate.booking.code : ""): "",
        ReferralSC: Referral.sellingCompany_Code != null ? Referral.sellingCompany_Code : "",
        AdvocateSC: Referral.referralAdvocate != null ? (Referral.referralAdvocate.booking != null ?  Referral.referralAdvocate.booking.sellingCompany_Code : ""): "",
        CreationDate: Referral.createdDate,
        Status: Referral.referralCredits.status,
        StatusColor: statusStyle[Referral.referralCredits.status],
        ShowGift: Referral.referralCredits.status == 'Created' || Referral.referralCredits.status == 'PartiallyRedeemed' ? true : false
      };
    },
    refreshList() {
      this.retrieveReferralCredits(this.$route.params.id);
    },
    openDialog(id) {
      this.$refs.modal.dialog = true;
      this.id = id;
    },
    dialogData() {
      ReferralCreditService.redeem(this.id)
      .then((response) => {
        console.log(response);
        this.messageRedeem.text = "The Referral Credit was redeemed!";
        this.messageRedeem.type = "success";
        this.refreshList();
      })
      .catch((e) => {
        console.log(e);
        this.messageRedeem.text = e;
        this.messageRedeem.type = "error";
      });
    },
    editCredits(id) {
      this.$router.push({ name: "UpdateCredits", params: { id: id } });
    }
  },
  components: {
    BackButton,
    Search,
    Pagination,
    Loading,
    Dialog,
    Alert
  },
  mounted() {
    this.getReferralAdvocate(this.$route.params.id);
    this.retrieveReferralCredits(this.$route.params.id);
  },
  watch: {
    options: {
      handler () {
        let sorter = this.options.sortBy[0];
        let sortDir = this.options.sortDesc[0] ? '_dsc' : '_asc';
        if (sorter == undefined) {
          return;
        } else if (sorter === 'RedemptionDate') {
          sorter = 'redemptionDate';
        } else {
          sorter = sorter.toLowerCase();
        }
        this.sortBy = sorter + sortDir;
        this.loadTable = true;
        this.retrieveReferralCredits(this.$route.params.id);
      }
    },
  }
};
</script>