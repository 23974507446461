<template>
  <v-row align="center">
    <v-col cols="12" class="mt-3 pb-0"> 
      <h1>Credit Reports</h1>
    </v-col>    
    <div class="col-sm-8 col-md-10 col-lg-8 mx-auto">    
      <div class="text-center">
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="8" md="6" class="pb-0 pt-10">
              <Datepicker @val="datepickerValueStart" :val.sync="this.startDate" label="Start Date" validation="Start Date is required" />
            </v-col>
            <v-col cols="8" md="6" class="pb-0 pt-10">
              <Datepicker @val="datepickerValueEnd" :val.sync="this.endDate" label="End Date" validation="End Date is required" />
            </v-col>

            <!-- Multi-select for Brands -->
            <v-col cols="8" md="6" class="pb-0 pt-0">
              <MultiSelect
                v-model="brands"
                :selectItems="brandItems"
                label="Brands"
                :hasValue="true"
              />
            </v-col>                        
            
            <!-- Multi-select for Referral Campaigns -->
            <v-col cols="8" md="6" class="pb-0 pt-0">
              <MultiSelect
                v-model="campaigns"
                :selectItems="referralCampaignItems"
                label="Referral Campaign"
                :hasValue="brands.length > 0"
              />
            </v-col>
            <Search @search-query="handleSearchQuery" />
          </v-row>
        </v-form>
      </div>  
    </div>

    <v-col cols="12" class="pt-0">
      <v-data-table
        :headers="headers"
        :items="ReferralAdvocates"
        class="elevation-2"
        :loading="loadTable"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :server-items-length="count"
        hide-default-footer
        dense        
      >
      </v-data-table>
    </v-col>
    <!-- Pagination Component -->
    <Pagination :count="count" :totalPages="totalPages" :pageSize="pageSize" :pageIndex="pageIndex" />
  </v-row>
</template>

<script>
import BrandService from "../../services/BrandDataService";
import ReportDataService from "../../services/ReportDataService";
import ReferralCampaignService from "../../services/ReferralCampaignDataService";
import Search from '@/components/Common/SearchEmit.vue';
import Pagination from '@/components/Common/Pagination.vue';
import Datepicker from "@/components/Common/Datepicker.vue";
import MultiSelect from "@/components/Common/MultiSelect.vue";

export default {
  name: "CreditReports",
  data() {
    return {
      ReferralAdvocates: [],
      headers: [
        { text: "Email", value: "EmailAddress", sortable: false },
        { text: "Name", value: "Name", sortable: false },
        { text: "Last Name", value: "LastName", sortable: false },
        { text: "Referrals Completed", value: "CompletedReferrals" },
        { text: "Total", value: "Total" },
        { text: "Available", value: "Available" },
        { text: "Redeemed", value: "Redeemed" }
      ],
      query: "",
      pageIndex: 1,
      totalPages: 0,
      pageSize: 10,
      count: 0,
      brand: "",
      sortBy: "date_dsc",
      role: "",
      campaigns: [],
      brands: [],
      brandItems: [],
      referralCampaignItems: [],
      startDate: new Date(new Date().getFullYear(), 0, 1).toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      loadTable: true,
      options: {},
      BrandsParams: {
        query: "",
        pageIndex: 0,
        pageSize: 100,
        sortBy: "date_asc"
      },
      ReferralCampaignsParams: {
        query: "",
        pageIndex: 0,
        pageSize: 100,
        sortBy: "date_asc",
        brands: "*"
      },
      ReportParams: {
        query: "",
        pageIndex: 0,
        pageSize: 100,
        brands: "*",
        startDate: new Date(new Date().getFullYear(), 0, 1).toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      } 
    };
  },
  methods: {
    getBrands() {
      BrandService.getAll(this.BrandsParams)
        .then((response) => {
          let brandsDDL = [{ text: "All Brands", id: "*" }];
          response.data.data.forEach((brand) => {
            brandsDDL.push({ text: brand.name, id: brand.code });
          });
          this.brandItems = brandsDDL;
        })
        .catch((error) => {
          console.error("Error loading brands:", error);
        });
    },
    getReferralCampaigns() {
      this.ReferralCampaignsParams.brands = this.brands.length ? this.brands.join(',') : "*";
      ReferralCampaignService.getAll(this.ReferralCampaignsParams)
        .then((response) => {
          let campaignsDDL = [{ text: "All campaigns", id: "0" }]; 
          response.data.data.forEach((campaign) => {
            campaignsDDL.push({ text: campaign.campaignName, id: campaign.id.toString() });
          });
          this.referralCampaignItems = campaignsDDL;
        })
        .catch((error) => {
          console.error("Error loading campaigns:", error);
        });
    },
    getReportParams() {
      this.ReportParams.query = this.query;
      this.ReportParams.pageIndex = this.pageIndex - 1;
      this.ReportParams.pageSize = this.pageSize;
      this.ReportParams.brands = this.brands.length ? this.brands.join(',') : "*";
      this.ReportParams.campaigns = this.campaigns.length ? this.campaigns.join(',') : "0";
      this.ReportParams.startDate = this.startDate;
      this.ReportParams.endDate = this.endDate;
      this.ReportParams.sortBy = this.sortBy;
    },
    getCreditReport() {
      this.getReportParams();
      ReportDataService.getCreditsReport(this.ReportParams)
        .then((response) => {
          this.ReferralAdvocates = response.data.data.map(this.getDisplayCredits);
          this.totalPages = Math.ceil(response.data.count / response.data.pageSize);
          this.count = response.data.count;
          this.loadTable = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    refreshList() {
      this.getCreditReport();
    },
    getDisplayCredits(Referral) {
      return {
        EmailAddress: Referral.advocateEmailAddress,
        Name: Referral.advocateFirstName,
        LastName: Referral.advocateLastName,
        CompletedReferrals: Referral.completeReferrals,
        Total: Referral.total,
        Available: Referral.available,
        Redeemed: Referral.redeemed,
      };
    },
    datepickerValueStart(value) {
      this.startDate = value;
      this.refreshList();
    },
    datepickerValueEnd(value) {
      this.endDate = value;
      this.refreshList();
    },
    handleSearchQuery(query) {
      this.query = query;
      this.pageIndex = 1;
      this.refreshList();
    }
  },
  components: {
    Search,
    Pagination, 
    MultiSelect,
    Datepicker,
  },
  watch: {
    brands() {
      this.pageIndex = 1;
      this.loadTable = true;
      this.campaigns = [];
      this.getReferralCampaigns();
      this.getCreditReport();
    },
    campaigns() {
      this.pageIndex = 1;
      this.loadTable = true;
      this.getCreditReport();
    },
    options: {
      handler() {
        let sorter = this.options.sortBy[0];
        let sortDir = this.options.sortDesc[0] ? '_dsc' : '_asc';
        if (sorter == undefined) {
          return;
        } else if (sorter === 'CompletedReferrals') {
          sorter = 'complete';
        } else if (sorter === 'SellingCompany') {
          sorter = 'company';
        } else {
          sorter = sorter.toLowerCase();
        }
        this.sortBy = sorter.toLowerCase() + sortDir;
        this.getCreditReport();
      },
      deep: true,
    },
  },
  mounted() {
    this.getBrands();
    this.getReferralCampaigns();
    this.getCreditReport();
  },
};
</script>
