<template>
  <v-col cols="12" sm="auto">
    <v-btn :to="link" color="primary">
      Add
    </v-btn>
  </v-col>
</template>

<script>
export default {
  props: {
    'link': String
  }
}
</script>