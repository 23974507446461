import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class ReferralDataService {
  getAll(params) {
    const { pageSize, pageIndex, sortBy, query, brand, role, referralCampaignId } = params;
    return http.get(`/Referrals/items`, {
      params: {
        pageSize,
        pageIndex,
        sortBy,
        query,
        brand,
        role,
        referralCampaignId 
      }
    });
  }

  get(id) {
    return http.get(`/Referrals/item/${id}`);
  }

  resetBooking(id) {
    return http.put(`/Referrals/item/${id}/ResetBooking`);
  }

  find(params) {
    const { brand, pageSize, pageIndex, sortBy, query, role } = params;
    return http.get(`/Referrals/items/brand/${brand}`, {
      params: {
        pageSize,
        pageIndex,
        sortBy,
        query,
        role
      }
    });
  }
}

export default new ReferralDataService();