<template>
  <v-col cols="12" class="pt-0">
    <v-row no-gutters align="center">
      <v-col cols="12" sm="3" md="2">
        <v-select
          v-model="paginationSize"
          :items="pageSizes"
          label="Items per Page"
          @change="handlePageSizeChange"
          v-if="count > pageSize"
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="auto" class="mr-sm-n2" v-if="totalPages > 1">
        <v-pagination
          v-model="pagination"
          :length="totalPages"
          total-visible="7"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          @input="handlePageChange"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "Pagination",
  data() {
    return { 
      pageSizes: [10, 20, 50, 100],
      pagination: 1,
      paginationSize: 10
    }
  },
  props: {
    'count': Number,
    'totalPages': Number,
    'pageSize': Number,
    'pageIndex': Number
  },
  methods: {
    handlePageSizeChange(size) {
      this.$parent.$data.pageSize = size;
      this.$parent.$data.pageIndex = 1;
      this.pagination = 1;
      this.$parent.$data.loadTable = true;
      this.$parent.refreshList();
    },
    handlePageChange(value) {
      this.$parent.$data.pageIndex = value;
      this.$parent.$data.loadTable = true;
      this.$parent.refreshList();
    }
  }
};
</script>