<template>
  <v-row align="center">
    <v-col cols="12" class="mt-3 pb-0"> 
      <h1>Selling Companies</h1>
      <Alert :resp="message" css="mt-5" />
    </v-col>
    <v-col cols="12" class="mt-3 pb-0 pl-0"> 
      <AddButton link="/SellingCompany/Add" />
    </v-col>
    <v-col cols="12" md="6" class="pb-0 pt-0">
      <Select v-model="brandCode" :val="brandCode" :selectItems="dropdownItems" label="Brands" :hasValue="true" />    
    </v-col>    
    <Search />
    <v-col cols="12" class="pt-0">
      <v-data-table
        :headers="headers"
        :items="SellingCompanies"
        disable-pagination
        class="elevation-2"
        :loading="loadTable"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :server-items-length="count"
        :hide-default-footer="true"
      >
        <template v-slot:[`item.Brand`]="{ item }">
          <router-link :to="{ name: 'UpdateBrand', params: { code: item.Brand } }">
            {{ item.Brand }}
          </router-link>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editSellingCompany(item.Code)">mdi-pencil</v-icon>
          <v-icon small @click="openDialog(item.Code)">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <Dialog text="Selling Company" verb="delete" ref="modal" />
    </v-col>
    <Pagination :count="count" :totalPages="totalPages" :pageSize="pageSize" :pageIndex="pageIndex" />
  </v-row>
</template>

<script>
import SellingCompaniesService from "../../services/SellingCompanyDataService";
import Search from '@/components/Common/Search.vue';
import AddButton from '@/components/Common/AddButton.vue';
import Pagination from '@/components/Common/Pagination.vue';
import Dialog from '@/components/Common/Dialog.vue';
import Alert from '@/components/Common/Alert.vue';
import BrandService from "../../services/BrandDataService";
import Select from '@/components/Common/Select.vue';

export default {
  name: "ListSellingCompanies",
  data() {
    return {
      SellingCompanies: [],
      headers: [
        { text: "Code", value: "Code" },
        { text: "Account Name", value: "AccountName", sortable: false },
        { text: "Promo Code", value: "PromoCode", sortable: false },
        { text: "NotificationEmail", value: "NotificationEmail", sortable: false },
        { text: "Credit Limit", value: "CreditLimit", sortable: false },
        { text: "Business Unit Lookup", value: "BusinessUnitLookup" },
        { text: "Brand", value: "Brand", sortable: false },
        { text: "Created Date", value: "CreatedDate" },
        { text: "Actions", value: "actions", sortable: false },
      ], 
      query: "",
      pageIndex: 1,
      totalPages: 0,
      pageSize: 10,
      count: 0,
      brand: "",
      sortBy: "date_dsc",
      loadTable: true,
      brandCode: "*",
      BrandsParams: {
        query : "",
        pageIndex : 0,
        pageSize : 100,
        sortBy : "date_asc"
      },
      dropdownItems: [],
      options: {},
      message: {
        text: "",
        type: ""
      }
    };
  },
  methods: {
    getRequestParams(query, pageIndex, pageSize, brand, sortBy) {
      let params = {};
      params["query"] = query;
      if (pageIndex) {
        params["pageIndex"] = pageIndex - 1;
      }
      if (pageSize) {
        params["pageSize"] = pageSize;
      }
      if (brand) {
        params["brand"] = brand;
      }
      if (sortBy) {
        params["sortBy"] = sortBy;
      }
      params["brand"] = brand;
      return params;
    },
    retrieveSellingCompanies() {
      const params = this.getRequestParams(
        this.query,
        this.pageIndex,
        this.pageSize,
        this.brandCode,
        this.sortBy
      );
      SellingCompaniesService.getAll(params)
      .then((response) => {
        this.SellingCompanies = response.data.data.map(this.getDisplaySellingCompany);
        this.totalPages = Math.ceil(response.data.count/response.data.pageSize,1);
        this.count = response.data.count;
        this.loadTable = false;
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    }, 
    refreshList() {
      this.retrieveSellingCompanies();
    },
    editSellingCompany(code) {
      this.$router.push({ name: "UpdateSellingCompany", params: { code: code } });
    },
    getDisplaySellingCompany(SellingCompany) {
      return {
        Code: SellingCompany.code,
        AccountName: SellingCompany.accountName,
        PromoCode: SellingCompany.promoCode,
        NotificationEmail: SellingCompany.notificationEmail,        
        CreditLimit: SellingCompany.creditLimit.toFixed(2),
        BusinessUnitLookup: SellingCompany.businessUnitLookup,
        Brand: SellingCompany.brand_Code,
        CreatedDate: SellingCompany.createdDate
      };
    },
    openDialog(id) {
      this.$refs.modal.dialog = true;
      this.id = id;
    },
    dialogData() {
      SellingCompaniesService.delete(this.id)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The Selling Company was deleted successfully!";
          this.message.type = "success";
          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    getBrands() {
      BrandService.getAll(this.BrandsParams)
      .then((response) => {
        let brands = [{text: "All brands", id: "*"}];
          let obj = response.data.data;
          for (var i in obj) {
            brands.push({text: obj[i].name, id: obj[i].code})
          }
          this.dropdownItems = brands;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  components: {
    Search,
    AddButton,
    Pagination,
    Dialog,
    Alert,
    Select
  },
  watch: {
    brandCode: function(value) {
      this.pageIndex = 1;
      this.loadTable = true;
      this.brandCode = value;
      this.retrieveSellingCompanies();
    },
    options: {
      handler () {
        let sorter = this.options.sortBy[0];
        let sortDir = this.options.sortDesc[0] ? '_dsc' : '_asc';
        if (sorter == undefined) {
          return;
        } else if (sorter === 'CreatedDate') {
          sorter = 'date';
        } else if (sorter === 'BusinessUnitLookup') {
          sorter = 'businessUnitLookup';
        } else {
          sorter = sorter.toLowerCase();
        }
        this.sortBy = sorter + sortDir;
        this.loadTable = true;
        this.retrieveSellingCompanies();
      }
    },
  },
  mounted() {
    this.retrieveSellingCompanies();
    this.getBrands();
  },
};
</script>