import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class ReferralCampaignDataService {
  getAll(params) {
    return http.get(`/ReferralCampaigns/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&brands=${params.brands}&sortBy=${params.sortBy}&query=${params.query}`);
  }

  get(id) {
    return http.get(`/ReferralCampaigns/item/${id}`);
  }

  create(data) {
    return http.post("/ReferralCampaigns/item/", data);
  }

  update(data) {
    return http.put(`/ReferralCampaigns/item/`, data);
  }

  delete(campaignId) {
    return http.delete(`/ReferralCampaigns/item/${campaignId}`);
  }

  find(params) {
    return http.get(`/ReferralCampaigns/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&brand=${params.brand}&sortBy=${params.sortBy}&query=${params.query}`);
  }
}

export default new ReferralCampaignDataService();