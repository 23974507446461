import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class BookingDataService {
  create(data) {
    return http.post("/Bookings/item/", data);
  }
  update(data) {
    return http.put(`/Bookings/item/`, data);
  }
}
export default new BookingDataService();