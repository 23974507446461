import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class BrandDataService {
  getAll(params) {
    return http.get(`/Brands/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&sortBy=${params.sortBy}&query=${params.query}`);
  }

  get(code) {
    return http.get(`/Brands/item/${code}`);
  }

  create(data) {
    return http.post("/Brands/item/", data);
  }

  update(data) {
    return http.put(`/Brands/item/`, data);
  }

  delete(code) {
    return http.delete(`/Brands/item/${code}`);
  }

  find(params) {
    return http.get(`/Brands/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&sortBy=${params.sortBy}&query=${params.query}`);
  }
}

export default new BrandDataService();