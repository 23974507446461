/* eslint-disable */
import Vue from "vue";
import Router from "vue-router";

import Home from '@/components/Home';
import Health from '@/components/Health';

import ListReferrals from '@/components/Referrals/ListReferrals';
import UpdateReferral from '@/components/Referrals/UpdateReferral';
import UploadReferrals from '@/components/Referrals/UploadReferrals';

import AddReferralCampaign from '@/components/ReferralCampaigns/AddReferralCampaign';
import ListReferralCampaigns from '@/components/ReferralCampaigns/ListReferralCampaigns';
import UpdateReferralCampaign from '@/components/ReferralCampaigns/UpdateReferralCampaign';

import ListReferralAdvocates from '@/components/ReferralAdvocates/ListReferralAdvocates';
import UpdateReferralAdvocate from '@/components/ReferralAdvocates/UpdateReferralAdvocate';
import ViewReferralAdvocate from '@/components/ReferralAdvocates/UpdateReferralAdvocate';

import AddBrand from '@/components/Brands/AddBrand';
import ListBrands from '@/components/Brands/ListBrands';
import UpdateBrand from '@/components/Brands/UpdateBrand';
import ViewBrand from '@/components/Brands/UpdateBrand';

import AddSellingCompany from '@/components/SellingCompanies/AddSellingCompany';
import ListSellingCompanies from '@/components/SellingCompanies/ListSellingCompanies';
import UpdateSellingCompany from '@/components/SellingCompanies/UpdateSellingCompany';
import ViewSellingCompany from '@/components/SellingCompanies/UpdateSellingCompany';

import AddReferralEmail from '@/components/ReferralEmails/AddReferralEmail';
import ListReferralEmails from '@/components/ReferralEmails/ListReferralEmails';
import UpdateReferralEmail from '@/components/ReferralEmails/UpdateReferralEmail';
import ViewReferralEmail from '@/components/ReferralEmails/UpdateReferralEmail';

import UpdateCredits from '@/components/Credits/UpdateCredits';

import ReferralReports from '@/components/Reports/ReferralReports';
import CreditReports from '@/components/Reports/CreditReports';

import LogoutSuccess from '@/components/LogoutSuccess';

import Auth from '@/app/auth';
import UserInfoApi from '@/app/user-info-api';
import ErrorComponent from '@/components/Error';
import Store from '../store/';

Vue.use(Router);

async function requireAuth(to, from, next) {
  if (!Auth.auth.isUserSignedIn()) {
    Store.commit('setLoggedIn', false);
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  } else {
    const authorize = to.meta.authorize;
    if (authorize) {
      if (!Store.getters.groups.length) {
        Store.commit('setGroupsInfo', UserInfoApi.getGroups());
      }
      if (!Store.getters.groups.some(role => authorize.indexOf(role) >= 0)) {
        return next({ path: '/' });
      }
    }
    UserInfoApi.getUserInfo().then(response => {
      Store.commit('setLoggedIn', true);
      Store.commit('setCognitoInfo', response.data);
      next();
    });
  }
}

const metaTitle = "TTC - Referrals | ";
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Home',
      authorize: ['Admin-UAT', 'Admin-PROD', 'ReservationsAdmin-UAT', 'ReservationsAdmin-PROD', 'ReservationsAgent-UAT', 'ReservationsAgent-PROD']
    }
  },
  {
    path: '/health',
    name: 'Health',
    component: Health,
    meta: {
      title: metaTitle + 'Health'
    }
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter(to, from, next) {
      Auth.auth.getSession();
    },
    meta: {
      title: metaTitle + 'Login'
    }
  },
  {
    path: '/login/oauth2/code/cognito', beforeEnter(to, from, next) {
      var currUrl = window.location.href;
      //console.log(currUrl);
      Auth.auth.parseCognitoWebResponse(currUrl);
      //next();
    },
    meta: {
      title: metaTitle + 'Login'
    }
  },
  {
    path: '/logout',
    name: 'LogoutSuccess',
    component: LogoutSuccess,
    beforeEnter(to, from, next) {
      Auth.logout();
      next();
    },
    meta: {
      title: metaTitle + 'Logout'
    }
  },
  {
    path: '/error',
    name: 'ErrorComponent',
    component: ErrorComponent,
    meta: {
      title: metaTitle + 'Error'
    }
  },
  {
    path: "/ReferralCampaigns",
    name: "ReferralCampaigns",
    component: ListReferralCampaigns,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Referral Campaigns',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/ReferralCampaign/Update/:id",
    name: "UpdateReferralCampaign",
    component: UpdateReferralCampaign,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Edit Referral Campaign',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/ReferralCampaign/Add",
    name: "AddReferralCampaign",
    component: AddReferralCampaign,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Add Referral Campaign',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/Referrals",
    name: "Referrals",
    component: ListReferrals,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Referrals',
      authorize: ['Admin-UAT', 'Admin-PROD', 'ReservationsAdmin-UAT', 'ReservationsAdmin-PROD', 'ReservationsAgent-UAT', 'ReservationsAgent-PROD']
    }
  },
  {
    path: "/Referrals/Update/:id",
    name: "UpdateReferral",
    component: UpdateReferral,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Edit Referral',
      authorize: ['Admin-UAT', 'Admin-PROD', 'ReservationsAdmin-UAT', 'ReservationsAdmin-PROD', 'ReservationsAgent-UAT', 'ReservationsAgent-PROD']
    }
  },
  {
    path: "/ReferralAdvocates",
    name: "ReferralAdvocates",
    component: ListReferralAdvocates,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Referral Advocates',
      authorize: ['Admin-UAT', 'Admin-PROD', 'ReservationsAdmin-UAT', 'ReservationsAdmin-PROD', 'ReservationsAgent-UAT', 'ReservationsAgent-PROD']
    }
  },
  {
    path: "/ReferralAdvocates/Update/:id",
    name: "UpdateReferralAdvocate",
    component: UpdateReferralAdvocate,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Edit Referral Advocate',
      authorize: ['Admin-UAT', 'Admin-PROD', 'ReservationsAdmin-UAT', 'ReservationsAdmin-PROD']
    }
  },
  {
    path: "/ReferralAdvocates/View/:id",
    name: "ViewReferralAdvocate",
    component: ViewReferralAdvocate,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'View Referral Advocate',
      authorize: ['Admin-UAT', 'Admin-PROD', 'ReservationsAdmin-UAT', 'ReservationsAdmin-PROD', 'ReservationsAgent-UAT', 'ReservationsAgent-PROD']
    }
  },
  {
    path: "/SellingCompanies",
    name: "SellingCompanies",
    component: ListSellingCompanies,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Selling Companies',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/SellingCompany/Update/:code",
    name: "UpdateSellingCompany",
    component: UpdateSellingCompany,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Edit Selling Company',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/SellingCompany/View/:code",
    name: "ViewSellingCompany",
    component: ViewSellingCompany,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'View Selling Company',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/SellingCompany/Add",
    name: "AddSellingCompany",
    component: AddSellingCompany,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Add Selling Company',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/Brands",
    name: "Brands",
    component: ListBrands,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Brands',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/Brand/Update/:code",
    name: "UpdateBrand",
    component: UpdateBrand,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Edit Brand',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/Brand/View/:code",
    name: "ViewBrand",
    component: ViewBrand,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'View Brand',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/Brand/Add",
    name: "AddBrand",
    component: AddBrand,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Add Brand',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/Credits/Update/:id",
    name: "UpdateCredits",
    component: UpdateCredits,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Edit Credits',
      authorize: ['Admin-UAT', 'Admin-PROD', 'ReservationsAdmin-UAT', 'ReservationsAdmin-PROD']
    }
  },
  {
    path: "/ReferralReports",
    name: "Referral Reports",
    component: ReferralReports,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Referral Reports',
      authorize: ['Admin-UAT', 'Admin-PROD', 'ReservationsAdmin-UAT', 'ReservationsAdmin-PROD', 'ReservationsAgent-UAT', 'ReservationsAgent-PROD']
    }
  },
  {
    path: "/CreditReports",
    name: "Credit Reports",
    component: CreditReports,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Credit Reports',
      authorize: ['Admin-UAT', 'Admin-PROD', 'ReservationsAdmin-UAT', 'ReservationsAdmin-PROD', 'ReservationsAgent-UAT', 'ReservationsAgent-PROD']
    }
  },
  {
    path: "/ReferralEmails/:code",
    name: "ReferralEmails",
    component: ListReferralEmails,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Referral Emails',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/ReferralEmail/Update/:id",
    name: "UpdateReferralEmail",
    component: UpdateReferralEmail,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Edit Referral Email',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/ReferralEmail/View/:id",
    name: "ViewReferralEmail",
    component: ViewReferralEmail,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'View Referral Email',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },
  {
    path: "/ReferralEmail/Add",
    name: "AddReferralEmail",
    component: AddReferralEmail,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Add Referral Email',
      authorize: ['Admin-UAT', 'Admin-PROD']
    }
  },   {
    path: "/Referrals/Upload",
    name: "UploadReferrals",
    component: UploadReferrals,
    beforeEnter: requireAuth,
    meta: {
      title: metaTitle + 'Upload Referrals',
      authorize: ['Admin-UAT', 'Admin-PROD', 'ReservationsAdmin-UAT', 'ReservationsAdmin-PROD']
    }
  },
  {
    path: "*",
    redirect: "/"
  }
]

const router = new Router({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title != undefined ? to.meta.title : metaTitle;
  Store.commit('setLastPage', from.path);
  next();
})

export default router;