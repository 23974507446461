<template>
  <v-select
    v-if="hasValue"
    v-model="currentData"
    :items="selectItems"
    item-value="id"
    item-text="text"
    :rules="[(v) => !!v || label + ' is required']"
    :label="label"
    required
  ></v-select>
  <v-select
    v-else
    v-model="currentData"
    :items="selectItems"
    :rules="[(v) => !!v || label + ' is required']"
    :label="label"
    required
  ></v-select>
</template>

<script>
export default {
  name: "Select",
  data() {
    return { 
      currentData: this.val
    }
  },
  props: {
    'val': String,
    'selectItems': Array,
    'label': String,
    'hasValue': Boolean
  },
  watch: {
    currentData: function (value) {
      this.$emit('input', value);
    },
    val: function() {  
      this.currentData = this.val;
    }
  }
};
</script>