<template>
  <div class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Add Referral Email</p>
    <div v-if="!submitted">
      <Alert :resp="message" />
      <v-form ref="form" lazy-validation>
          <v-row>
              <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
                  <v-text-field v-model="ReferralEmail.Name"
                                :rules="[(v) => !!v || 'Name is required']"
                                label="Name"
                                required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
                  <v-checkbox v-model="ReferralEmail.Disabled"
                              label="Disabled"></v-checkbox>
              </v-col>

              <v-col cols="12" class="pt-0 pb-0">
                  <v-text-field v-model="ReferralEmail.HTMLContent"
                                :rules="[(v) => !!v || 'HTML Content is required']"
                                label="HTML Content"
                                required></v-text-field>
              </v-col>  <v-col cols="12" md="6" class="pb-0">
                  <v-text-field v-model="ReferralEmail.SenderEmail"
                                label="Sender Email"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <Select v-model="ReferralEmail.Brand_Code" :selectItems="ddlBrand" label="Brands" :hasValue="true" />
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <Select v-model="ReferralEmail.Type_Id" :selectItems="ddlType" label="Types" :hasValue="true" />
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <BackButton />
                  <v-btn color="primary" @click="saveReferralEmail">
                      Add
                  </v-btn>
              </v-col>
          </v-row>
      </v-form>
    </div>

    <div v-else>
      <Alert :resp="{text: 'Submitted successfully!', type: 'success'}" />
      <p>Click the button to add new Referral Email.</p>
      <v-btn color="secondary" to="/ReferralEmails" class="mr-2">Back</v-btn>
      <v-btn color="primary" @click="newReferralEmail">Add</v-btn>
    </div>
  </div>
</template>

<script>
import ReferralEmailService from "../../services/ReferralEmailDataService";
import BrandService from "../../services/BrandDataService";
import ReferralEmailTypeService from "../../services/ReferralEmailTypeDataService";
import BackButton from "@/components/Common/BackButton.vue";
import Alert from '@/components/Common/Alert.vue';
import Select from '@/components/Common/Select.vue';
export default {
  name: "AddReferralEmail",
  data() {
    return {
      ReferralEmail: {      
        Name: "",
        HTMLContent: "",
        SenderEmail: "",
        Type_Id:"",
        Brand_Code: ""
      },
      ddlBrand: [],
      ddlType: [],
      params : {
        query : "",
        pageIndex : 0,
        pageSize : 10,
        sortBy : "date_asc"
      },
      submitted: false,
      message: {
        text: "",
        type: ""
      }
    };
  },
  methods: {
    getBrands() {
      BrandService.getAll(this.params)
      .then((response) => {
        let brands = [{text: "Select Brand", id: ""}];
          let obj = response.data.data;
          for (var i in obj) {
            brands.push({text: obj[i].name, id: obj[i].code})
          }
          this.ddlBrand = brands;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getReferralEmailTypes() {
      ReferralEmailTypeService.getAll(this.params)
        .then((response) => {
          let types = [{text: "Select Type", id: ""}];
          let obj = response.data.data;
          for (var i in obj) {
            types.push({text: obj[i].name, id: obj[i].id})
          }
          this.ddlType = types;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    saveReferralEmail() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == false) return;
      var data = {      
        Name: this.ReferralEmail.Name,
        HTMLContent: this.ReferralEmail.HTMLContent,
        SenderEmail: this.ReferralEmail.SenderEmail,
        Brand_Code: this.ReferralEmail.Brand_Code,
        Type_Id: this.ReferralEmail.Type_Id
      };

      ReferralEmailService.create(data)
        .then((response) => {  
          console.log(response.data);
          this.ReferralEmail.Id = response.data.Id;        
          this.submitted = true;
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    newReferralEmail() {
      this.submitted = false;
      this.ReferralEmail = {};
    }
  },
  mounted() {
    this.getReferralEmailTypes();
    this.getBrands();
  },
  components: {
    BackButton,
    Alert,
    Select
  }  
};
</script>