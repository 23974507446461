<template>
  <div v-if="currentReferral" class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Edit Referral</p>
    <Alert :resp="message" />
    <v-form ref="form" lazy-validation :disabled="bookingConfirmed">
      <v-row>
        <v-col cols="12">
          <h3>Site</h3>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field
            v-model="currentReferral.referralSellingCompany.brand.name"   
            label="Site"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field
            v-model="currentReferral.code"   
            label="Code"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <h3>Referral</h3>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field
            v-model="currentReferral.user.firstName"   
            label="First Name"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field
            v-model="currentReferral.user.lastName"   
            label="Last Name"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field
            v-model="currentReferral.user.emailAddress"   
            label="Email Address"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <h3>Booking</h3>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field v-model="currentReferral.referralBooking.code"
            label="Booking Code"
            :rules="[(v) => !!v || 'Booking Code is required']"
            maxlength="15"
            hint="Booking Code can be up to 15 characters"
            required
          ></v-text-field>          
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field type="number"
            v-model="currentReferral.referralBooking.guestCount"   
            label="Guest Count"    
            :rules="[(v) => !!v || 'Guest Count is required', (v) => v >= 0 || 'Guest Count must be a positive number']"
            required
            :min="0"
            oninput="validity.valid||(value='');"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <Select v-model="currentReferral.referralBooking.sellingCompany_Code" :val="currentReferral.referralBooking.sellingCompany_Code" :selectItems="dropdownItems" label="Selling Company" />
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-checkbox
            v-model="currentReferral.referralBooking.confirmed"
            label="Booking Confirmed (Paid in Full)"
            :disabled="$store.getters.resAgent"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <BackButton />
          <!--<v-btn color="error" class="mr-2" @click="$refs.modal.dialog = true" v-if="!$store.getters.resAgent && ($store.getters.resAdmin && !bookingConfirmed) || $store.getters.admin">-->
          <v-btn color="error" class="mr-2" @click="$refs.modal.dialog = true" v-if="!$store.getters.resAgent && !newBooking">
            Reset
          </v-btn>
          <v-btn color="primary" @click="updateReferral" v-if="!bookingConfirmed">
            Update
          </v-btn>
          <Dialog text="Booking information and associated credits" verb="reset" ref="modal" v-if="!$store.getters.resAgent" />
        </v-col>
      </v-row>
    </v-form>
  </div>

  <div v-else class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <Loading text="Referral" />
  </div>
</template>

<script>
import ReferralService from "../../services/ReferralDataService";
import BookingService from "../../services/BookingDataService";
import SellingCompanyDataService from "../../services/SellingCompanyDataService";
import BackButton from "@/components/Common/BackButton.vue";
import Loading from "@/components/Common/Loading.vue";
import Alert from '@/components/Common/Alert.vue';
import Select from '@/components/Common/Select.vue';
import Dialog from '@/components/Common/Dialog.vue';
export default {
  name: "Referral",
  data() {
    return {
      currentReferral: null,
      message: {
        text: "",
        type: ""
      },
      newBooking : false,
      dropdownItems: [],
      params : {
        query : "",
        pageIndex : 0,
        pageSize : 100,
        sortBy : "date_asc",
        brand : "*"
      },
      bookingConfirmed: false
    };
  },
  methods: {
    getSellingCompanies() {
      SellingCompanyDataService.getAll(this.params)
        .then((response) => {
          this.dropdownItems = response.data.data.map(function(v){return v.code;});
          console.log(this.dropdownItems);
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    getReferral(id) {
      ReferralService.get(id)
        .then((response) => {
          this.currentReferral = response.data;
          if(this.currentReferral.referralBooking == null){
            var newBooking = {
              code: "",
              confirmed: false,
              guestCount: 0,
              sellingCompany: null,
              sellingCompany_Code: ""
            }
            this.newBooking = true;
            this.currentReferral.referralBooking = newBooking;
          }
          this.bookingConfirmed = this.currentReferral.referralBooking.confirmed;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    updateReferral() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == false) return;
      if (this.newBooking){
        BookingService.create(this.currentReferral)
        .then((response) => {
          console.log(response.data);
          this.currentReferral.referralBooking = response.data.data;
          this.message.text = "The Booking was created successfully!";
          this.message.type = "success";
          this.newBooking = false;
          this.bookingConfirmed = this.currentReferral.referralBooking.confirmed;
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
        console.log(this.currentReferral);
      } else {
        BookingService.update(this.currentReferral.referralBooking)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The Booking was updated successfully!";
          this.message.type = "success";
          this.bookingConfirmed = this.currentReferral.referralBooking.confirmed;
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
      }
    },
    dialogData() {
      console.log(this.currentReferral.referralBooking)
      ReferralService.resetBooking(this.currentReferral.id)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The Booking was reset successfully!";
          this.message.type = "success";
          this.currentReferral.referralBooking.code = '';
          this.currentReferral.referralBooking.guestCount = 0;
          this.currentReferral.referralBooking.sellingCompany_Code = '';
          this.currentReferral.referralBooking.confirmed = false;
          this.bookingConfirmed = this.currentReferral.referralBooking.confirmed;
          this.newBooking = true;
          this.$refs.form.resetValidation();
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    }
  },
  mounted() {
    this.getReferral(this.$route.params.id);
    this.getSellingCompanies();
  },
  components: {
    BackButton,
    Loading,
    Alert,
    Select,
    Dialog
  }
};
</script>