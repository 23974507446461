<template>
  <v-col cols="12" sm="auto">
    <v-btn-toggle
        v-model="currentRole"
        dense
        mandatory
      >
        <v-btn
          value="admin"
        >
          {{toggleOne}}
        </v-btn>
        <v-btn
          value="TravelAdvisors"
        >
          {{toggleTwo}}
        </v-btn>
      </v-btn-toggle>
  </v-col>
</template>

<script>
export default {
  name: "Switcher",
  data () {
    return {
      currentRole: this.val === '' ? 'admin' : this.val
    }
  },
  props: {
    'val': String,
    'toggleOne': String,
    'toggleTwo': String
  },
  watch: {
    currentRole: function (value) {
      this.$parent.$data.pageIndex = 1;
      this.$parent.$data.loadTable = true;
      this.$parent.$data.role = value === 'admin' ? '' : value;
      this.$parent.refreshList();
    }
  }
}
</script>