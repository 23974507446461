<template>
  <div v-if="currentBrand" class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Edit Brand</p>
    <Alert :resp="message" />
    <v-form ref="form" lazy-validation>
        <v-row>
            <v-col cols="12" md="6" class="pb-0">
                <v-text-field v-model="currentBrand.code"
                              :rules="[(v) => !!v || 'Code is required']"
                              label="Code"
                              required
                              disabled></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
                <v-text-field v-model="currentBrand.name"
                              :rules="[(v) => !!v || 'Name is required']"
                              label="Name"
                              required></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-text-field type="number"
                              v-model="currentBrand.creditExpirationMonths"
                              :rules="[(v) => !!v || 'Credit Expiration Months is required']"
                              label="Credit Expiration Months"
                              required
                              :min="1"
                              oninput="validity.valid||(value='');"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-text-field type="number"
                              v-model="currentBrand.referralCodeExpirationMonths"
                              :rules="[(v) => !!v || 'Referral Code Expiration Months is required']"
                              label="Referral Code Expiration Months"
                              required
                              :min="1"
                              oninput="validity.valid||(value='');"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-text-field type="number"
                              v-model="currentBrand.brandGroup"
                              :rules="[(v) => !!v || 'Brand Group is required']"
                              label="Brand Group"
                              required
                              :min="1"
                              oninput="validity.valid||(value='');"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
                <v-text-field v-model="currentBrand.url"
                              label="URL"
                              required></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="12">
                <BackButton />
                <v-btn color="error" class="mr-2" @click="$refs.modal.dialog = true">
                    Delete
                </v-btn>
                <v-btn color="primary" @click="updateBrand">
                    Update
                </v-btn>
                <Dialog text="Brand" verb="delete" ref="modal" />
            </v-col>
        </v-row>
    </v-form>
  </div>

  <div v-else-if="deletedBrand" class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Edit Brand</p>
    <Alert :resp="message" />
  </div>

  <div v-else class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <Loading text="Brand" />
  </div>
</template>

<script>
import BrandsService from "../../services/BrandDataService";
import BackButton from "@/components/Common/BackButton.vue";
import Loading from "@/components/Common/Loading.vue";
import Dialog from '@/components/Common/Dialog.vue';
import Alert from '@/components/Common/Alert.vue';
export default {
  name: "UpdateBrand",
  data() {
    return {
      currentBrand: null,
      deletedBrand: false,
      message: {
        text: "",
        type: ""
      }
    };
  },
  methods: {    
    getBrand(code) {
      BrandsService.get(code)
        .then((response) => {
          this.currentBrand = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateBrand() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == false) return;
      BrandsService.update(this.currentBrand)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The Brand was updated successfully!";
          this.message.type = "success";
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    dialogData() {
      BrandsService.delete(this.currentBrand.code)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The Brand was deleted successfully!";
          this.message.type = "success";
          this.currentBrand = null;
          this.deletedBrand = true;
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    }
  },
  mounted() {
    this.getBrand(this.$route.params.code);
  },
  components: {
    BackButton,
    Loading,
    Dialog,
    Alert
  }
};
</script>