import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class ReportDataService {
  // Helper function to handle array parameters (comma-separated)
  buildQueryParams(params) {
    const queryParams = new URLSearchParams();

    // Add parameters only if they exist
    if (params.query) queryParams.append('query', params.query);
    if (params.startDate) queryParams.append('startDate', params.startDate);
    if (params.endDate) queryParams.append('endDate', params.endDate);
    if (params.periodicity) queryParams.append('periodicity', params.periodicity);

    // Handle arrays: convert to comma-separated strings
     if (params.brands && Array.isArray(params.brands)) {
       queryParams.append('brands', params.brands.join(','));
     } else if (params.brands) {
      queryParams.append('brands', params.brands);
    }

    if (params.campaigns && Array.isArray(params.campaigns)) {
      queryParams.append('campaigns', params.campaigns.join(','));
    } else if (params.campaigns) {
      queryParams.append('campaigns', params.campaigns);
    }

    // Pagination and other parameters
    if (params.pageSize) queryParams.append('pageSize', params.pageSize);
    if (params.pageIndex) queryParams.append('pageIndex', params.pageIndex);
    if (params.sortBy) queryParams.append('sortBy', params.sortBy);
    return queryParams.toString();
  }

  // Fetch reports with new parameters handling
  getReport(params) {
    const queryString = this.buildQueryParams(params);
    return http.get(`/Report/items?${queryString}`);
  }

  // Fetch credits report (keeping as it is)
  getCreditsReport(params) {
    const queryString = this.buildQueryParams(params);
    return http.get(`/Report/items/Credits?${queryString}`);
  }
}

export default new ReportDataService();
