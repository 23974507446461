<template>
  <v-dialog
    v-model="dialog"
    max-width="300"
  >
    <v-card>
      <v-card-title class="headline">
        Do you want to {{verb}} this {{text}}?
      </v-card-title>
      <v-card-text>This action can't be undone.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="dialogOk"
        >
          Agree
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Dialog",
  data() {
    return { 
      dialog: false
    }
  },
  props: {
    'text': String,
    'verb': String
  },
  methods: {
    dialogOk() {
      this.dialog = false;
      if (this.$parent.dialogData != undefined) {
        this.$parent.dialogData();
      } else {
        this.$parent.$parent.dialogData();
      }
    }
  }
};
</script>