import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class ReferralAdvocateDataService {
  getAll(params) {
    return http.get(`/ReferralAdvocates/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&sortBy=${params.sortBy}&query=${params.query}&role=${params.role}`);
  }

  get(id) {
    return http.get(`/ReferralAdvocates/item/${id}`);
  }

  update(data) {
    return http.put(`/ReferralAdvocates/item/`, data);
  }
  
  find(params) {
    return http.get(`/ReferralAdvocates/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&sortBy=${params.sortBy}&query=${params.query}&role=${params.role}`);
  }
}

export default new ReferralAdvocateDataService();