import Auth from '@/app/auth';
import jwt_decode from "jwt-decode";
import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

const IDENTITY_URI = process.env.VUE_APP_REFERRALS_IDENTITY_URI;

export default{  
    async getUserInfo(){       
        interceptor.setInterceptor();     
        const USERINFO_URL = 'https://'+ Auth.auth.getAppWebDomain() + '/oauth2/userInfo';
        return http.post(USERINFO_URL);
    },
    getGroups(){       
        var jwtToken = Auth.auth.getSignInUserSession().getAccessToken().jwtToken;             
        var decodeToken = jwt_decode(jwtToken);
        return decodeToken['cognito:groups'];
    },
    defaultRole(username){       
        interceptor.setInterceptor();
        const USERROLE_URL = IDENTITY_URI + '/identity/AddUserToDefaultGroup/' + username;
        return http.post(USERROLE_URL);
    }
}