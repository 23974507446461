<template>
  <div v-if="currentReferralEmail" class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Edit Referral Email</p>
    <v-alert
      outlined
      text
      prominent
      type="info"
    >
      <p class="text-h6 mb-0">
        Replacement Tags
      </p>
      <p>You can insert <strong>Replacement Tags</strong> in your email template using the following format: <span v-pre>{{tagname}}</span></p>
      <p class="mb-0">Example:</p>
      <p class="mb-0">Hi <span v-pre>{{User.FirstName}}</span>, here's your discount code.</p>
    </v-alert>
    <v-dialog
      v-model="modal"
      max-width="600"
    >
      <v-card>
        <v-toolbar
          dark
          color="info"
        >
          <v-btn
            icon
            dark
          >
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <v-toolbar-title>Available Replacement Tags</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            dark
            text
            rounded
            class="v-btn--round"
            @click="modal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-expansion-panels accordion focusable class="mt-5" v-if="this.currentReferralEmail.type_Id == 3">
            <v-expansion-panel>
              <v-expansion-panel-header>Referral Advocate</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4" v-pre>
                  <p>{{Id}}</p>
                  <p>{{CompletedReferrals}}</p>
                  <p>{{CreatedDate}}</p>
                  <p class="mb-0">{{UpdatedDate}}</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Referral Advocate User</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4" v-pre>
                  <p>{{User.EmailAddress}}</p>
                  <p>{{User.FirstName}}</p>
                  <p>{{User.LastName}}</p>
                  <p>{{User.Role}}</p>
                  <p>{{User.CreatedDate}}</p>
                  <p class="mb-0">{{User.UpdatedDate}}</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Referral Advocate Booking</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4" v-pre>
                  <p>{{Booking.Id}}</p>
                  <p>{{Booking.Code}}</p>
                  <p>{{Booking.Status}}</p>
                  <p>{{Booking.Confirmed}}</p>
                  <p>{{Booking.ConfirmedDate}}</p>
                  <p>{{Booking.GuestCount}}</p>
                  <p>{{Booking.CreatedDate}}</p>
                  <p>{{Booking.UpdatedDate}}</p>
                  <p>{{Booking.SellingCompany.Code}}</p>
                  <p>{{Booking.SellingCompany.AccountName}}</p>
                  <p>{{Booking.SellingCompany.PromoCode}}</p>
                  <p>{{Booking.SellingCompany.NotificationEmail}}</p>
                  <p>{{Booking.SellingCompany.CreditLimit}}</p>
                  <p>{{Booking.SellingCompany.CreatedDate}}</p>
                  <p>{{Booking.SellingCompany.Region.Currency}}</p>
                  <p>{{Booking.SellingCompany.Brand.Code}}</p>
                  <p>{{Booking.SellingCompany.Brand.Name}}</p>
                  <p>{{Booking.SellingCompany.Brand.CreditExpirationMonths}}</p>
                  <p>{{Booking.SellingCompany.Brand.ReferralCodeExpirationMonths}}</p>
                  <p>{{Booking.SellingCompany.Brand.BrandGroup}}</p>
                  <p>{{Booking.SellingCompany.Brand.CreatedDate}}</p>
                  <p class="mb-0">{{Booking.SellingCompany.Brand.UpdatedDate}}</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels accordion focusable class="mt-5" v-else>
            <v-expansion-panel>
              <v-expansion-panel-header>Referral Prospect</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4" v-pre>
                  <p>{{Id}}</p>
                  <p>{{Code}}</p>
                  <p>{{CreatedDate}}</p>
                  <p class="mb-0">{{UpdatedDate}}</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Referral Prospect User</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4" v-pre>
                  <p>{{User.EmailAddress}}</p>
                  <p>{{User.FirstName}}</p>
                  <p>{{User.LastName}}</p>
                  <p>{{User.Role}}</p>
                  <p>{{User.CreatedDate}}</p>
                  <p class="mb-0">{{User.UpdatedDate}}</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Referral Prospect Credit</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4" v-pre>
                  <p>{{ReferralCredits.Id}}</p>
                  <p>{{ReferralCredits.Amount}}</p>
                  <p>{{ReferralCredits.Status}}</p>
                  <p>{{ReferralCredits.Redeemed}}</p>
                  <p>{{ReferralCredits.ExpirationDate}}</p>
                  <p>{{ReferralCredits.RedemptionDate}}</p>
                  <p>{{ReferralCredits.CreatedDate}}</p>
                  <p class="mb-0">{{ReferralCredits.UpdatedDate}}</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Referral Prospect Booking</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4" v-pre>
                  <p>{{Booking.Id}}</p>
                  <p>{{Booking.Code}}</p>
                  <p>{{Booking.Status}}</p>
                  <p>{{Booking.Confirmed}}</p>
                  <p>{{Booking.ConfirmedDate}}</p>
                  <p>{{Booking.GuestCount}}</p>
                  <p>{{Booking.CreatedDate}}</p>
                  <p>{{Booking.UpdatedDate}}</p>
                  <p>{{Booking.SellingCompany.Code}}</p>
                  <p>{{Booking.SellingCompany.AccountName}}</p>
                  <p>{{Booking.SellingCompany.PromoCode}}</p>
                  <p>{{Booking.SellingCompany.NotificationEmail}}</p>
                  <p>{{Booking.SellingCompany.CreditLimit}}</p>
                  <p>{{Booking.SellingCompany.CreatedDate}}</p>
                  <p>{{Booking.SellingCompany.Brand.Code}}</p>
                  <p>{{Booking.SellingCompany.Brand.Name}}</p>
                  <p>{{Booking.SellingCompany.Brand.CreditExpirationMonths}}</p>
                  <p>{{Booking.SellingCompany.Brand.ReferralCodeExpirationMonths}}</p>
                  <p>{{Booking.SellingCompany.Brand.BrandGroup}}</p>
                  <p>{{Booking.SellingCompany.Brand.CreatedDate}}</p>
                  <p>{{Booking.SellingCompany.Brand.UpdatedDate}}</p>
                  <p class="mb-0">{{Booking.SellingCompany.Region.Currency}}</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <v-expansion-panel>
              <v-expansion-panel-header>Referral Selling Company</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4" v-pre>
                  <p>	{{ReferralSellingCompany.Code}}</p>
                  <p>	{{ReferralSellingCompany.AccountName}}</p>
                  <p>	{{ReferralSellingCompany.PromoCode}}</p>
                  <p>	{{ReferralSellingCompany.NotificationEmail}}</p>
                  <p>	{{ReferralSellingCompany.CreditLimit}}</p>
                  <p>	{{ReferralSellingCompany.CreatedDate}}</p>
                  <p>	{{ReferralSellingCompany.Region.Currency}}</p>
                  <p>	{{ReferralSellingCompany.Brand.Code}}</p>
                  <p>	{{ReferralSellingCompany.Brand.Name}}</p>
                  <p>	{{ReferralSellingCompany.Brand.CreditExpirationMonths}}</p>
                  <p>	{{ReferralSellingCompany.Brand.ReferralCodeExpirationMonths}}</p>
                  <p>	{{ReferralSellingCompany.Brand.BrandGroup}}</p>
                  <p>	{{ReferralSellingCompany.Brand.CreatedDate}}</p>
                  <p class="mb-0">{{ReferralSellingCompany.Brand.UpdatedDate}}	</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Referral Advocate</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4" v-pre>
                  <p>{{ReferralAdvocate.Id}}</p>
                  <p>{{ReferralAdvocate.CompletedReferrals}}</p>
                  <p>{{ReferralAdvocate.CreatedDate}}</p>
                  <p class="mb-0">{{ReferralAdvocate.UpdatedDate}}</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Referral Advocate User</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4" v-pre>
                  <p>{{ReferralAdvocate.User.EmailAddress}}</p>
                  <p>{{ReferralAdvocate.User.FirstName}}</p>
                  <p>{{ReferralAdvocate.User.LastName}}</p>
                  <p>{{ReferralAdvocate.User.Role}}</p>
                  <p>{{ReferralAdvocate.User.CreatedDate}}</p>
                  <p class="mb-0">{{ReferralAdvocate.User.UpdatedDate}}</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Referral Advocate Booking</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="mt-4" v-pre>
                  <p>{{ReferralAdvocate.Booking.Id}}</p>
                  <p>{{ReferralAdvocate.Booking.Code}}</p>
                  <p>{{ReferralAdvocate.Booking.Status}}</p>
                  <p>{{ReferralAdvocate.Booking.Confirmed}}</p>
                  <p>{{ReferralAdvocate.Booking.ConfirmedDate}}</p>
                  <p>{{ReferralAdvocate.Booking.GuestCount}}</p>
                  <p>{{ReferralAdvocate.Booking.CreatedDate}}</p>
                  <p>{{ReferralAdvocate.Booking.UpdatedDate}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.Code}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.AccountName}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.PromoCode}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.NotificationEmail}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.CreditLimit}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.CreatedDate}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.Region.Currency}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.Brand.Code}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.Brand.Name}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.Brand.CreditExpirationMonths}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.Brand.ReferralCodeExpirationMonths}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.Brand.BrandGroup}}</p>
                  <p>{{ReferralAdvocate.Booking.SellingCompany.Brand.CreatedDate}}</p>
                  <p class="mb-0">{{ReferralAdvocate.Booking.SellingCompany.Brand.UpdatedDate}}</p>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Alert :resp="message" />
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
          <v-text-field
            v-model="currentReferralEmail.name"
            :rules="[(v) => !!v || 'Name is required']"
            label=" Name"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-checkbox
            v-model="currentReferralEmail.disabled"
            label="Disabled"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6" class="pb-0">
          <Select v-model="currentReferralEmail.brand_Code" :val="currentReferralEmail.brand_Code" :selectItems="ddlBrand" label="Brand" />
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
            <v-text-field
              v-model="currentReferralEmail.senderEmail"
              label="SenderEmail"
            ></v-text-field>
          </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <SelectModal v-model="currentReferralEmail.type_Id" :val="currentReferralEmail.type_Id" :selectItems="ddlType" label="Type" :hasValue="true" />
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="currentReferralEmail.subject"
            :rules="[(v) => !!v || 'Subject is required']"
            label="Subject"
            hint="The subject line of the email. This property may contain replacement tags. When you send the email, you can specify a value for tagname for each destination."
            persistent-hint
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0 mb-4">
          <v-text-field
            v-model="currentReferralEmail.textContent"
            :rules="[(v) => !!v || 'Text Content is required']"
            label="Text Content"
            hint="The text body of the email. Recipients whose email clients don't display HTML email see this version of the email. This property may contain replacement tags"
            persistent-hint
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-textarea 
            v-model="currentReferralEmail.htmlContent"
            :rules="[(v) => !!v || 'HTML Content is required']"
            label="HTML Content"
            hint="The HTML body of the email. This property may contain replacement tags"
            persistent-hint
            required
          ></v-textarea>
          <p class="mt-3 mb-2">Preview:</p>
          <v-card class="mb-10">
            <div v-html="currentReferralEmail.htmlContent" />
          </v-card>
        </v-col>
        <v-col cols="12">
          <BackButton />
          <v-btn color="error" class="mr-2" @click="$refs.modal.dialog = true">
            Delete
          </v-btn>
          <v-btn color="primary" @click="updateReferralEmail">
            Update
          </v-btn>
          <Dialog text="Referral Email" verb="delete" ref="modal" />
        </v-col>
      </v-row>
    </v-form>
  </div>

  <div v-else-if="deletedCompany" class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Edit Referral Email</p>
    <Alert :resp="message" />
  </div>

  <div v-else class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <Loading text="Referral Email" />
  </div>
</template>

<script>
import ReferralEmailsService from "../../services/ReferralEmailDataService";
import BrandsService from "../../services/BrandDataService";
import ReferralEmailTypeService from "../../services/ReferralEmailTypeDataService";
import BackButton from "@/components/Common/BackButton.vue";
import Loading from "@/components/Common/Loading.vue";
import Dialog from '@/components/Common/Dialog.vue';
import Alert from '@/components/Common/Alert.vue';
import Select from '@/components/Common/Select.vue';
import SelectModal from '@/components/Common/SelectModal.vue';
export default {
  name: "UpdateReferralEmail",
  data() {
    return {
      currentReferralEmail: null,
      deletedCompany: false,
      message: {
        text: "",
        type: ""
      },
      ddlBrand: [],
      ddlType: [],
      params : {
        query : "",
        pageIndex : 0,
        pageSize : 100,
        sortBy : "date_asc"
      },
      modal: false
    };
  },
  methods: {
    getBrands() {
      BrandsService.getAll(this.params)
        .then((response) => {
          this.ddlBrand =response.data.data.map(function(v){return v.code;});
          console.log(this.ddlBrand);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getReferralEmailTypes() {
      ReferralEmailTypeService.getAll(this.params)
        .then((response) => {
          let types = [{text: "", id: ""}];
          let obj = response.data.data;
          for (var i in obj) {
            types.push({text: obj[i].name, id: obj[i].id.toString()})
          }
          this.ddlType = types;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getReferralEmail(id) {
      ReferralEmailsService.get(id)
        .then((response) => {
          this.currentReferralEmail = response.data;
          this.currentReferralEmail.type_Id = this.currentReferralEmail.type_Id.toString();
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateReferralEmail() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == false) return;
      ReferralEmailsService.update(this.currentReferralEmail)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The ReferralEmail was updated successfully!";
          this.message.type = "success";
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    dialogData() {
      ReferralEmailsService.delete(this.currentReferralEmail.id)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The ReferralEmail was deleted successfully!";
          this.message.type = "success";
          this.currentReferralEmail = null;
          this.deletedCompany = true;
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    showModal() {
      this.modal = true;
    }
  },
  mounted() {
    this.getReferralEmail(this.$route.params.id);
    this.getReferralEmailTypes();
    this.getBrands();
  },
  components: {
    BackButton,
    Loading,
    Dialog,
    Alert,
    Select,
    SelectModal
  }
};
</script>