<template>
  <v-row align="center">
    <v-col cols="12" class="mt-3 pb-0"> 
      <h1>Referrals</h1>
    </v-col>
    <v-col cols="12" class="mt-3 pb-0 pl-0"> 
      <Switcher toggleOne="Consumer Referrals" toggleTwo="Travel Advisor Referrals" v-if="!$store.getters.resAgent" />    
    </v-col>   
    <v-col cols="12" md="6" class="pb-0 pt-0">
        <Select v-model="brand" :val="brand" :selectItems="brandItems" label="Brands" :hasValue="true" />       

      <Select v-model="referralCampaignId" :val="referralCampaignId" :selectItems="referralCampaignItems" label="Referral Campaign" :hasValue="true" />
    </v-col>   
    <Search />   
    <v-col cols="12" class="pt-0">
        <v-data-table
          :headers="headers"
          :items="Referrals"
          disable-pagination
          class="elevation-2"
          :loading="loadTable"
          loading-text="Loading... Please wait"
          :options.sync="options"
          :server-items-length="count"
          :hide-default-footer="true">
          <template v-slot:[`item.AdvocateId`]="{ item }">
            <router-link :to="{ name: !$store.getters.resAgent ? 'UpdateReferralAdvocate' : 'ViewReferralAdvocate', params: { id: item.AdvocateId } }">
              {{ item.AdvocateId }}
            </router-link>
          </template>
          <template v-slot:[`item.SellingCompany`]="{ item }">
            <router-link :to="{ name: !$store.getters.resAgent ? 'UpdateSellingCompany' : 'ViewSellingCompany', params: { code: item.SellingCompany } }">
              {{ item.SellingCompany }}
            </router-link>
          </template>
          <template v-slot:[`item.booking`]="{ item }">
            <v-icon small class="mr-2" @click="updateBooking(item.Id)">mdi-pencil</v-icon>
          </template>
        </v-data-table>
    </v-col>
    <Pagination :count="count" :totalPages="totalPages" :pageSize="pageSize" :pageIndex="pageIndex" />
  </v-row>
</template>

<script>
import ReferralService from "../../services/ReferralDataService";
import ReferralCampaignService from "../../services/ReferralCampaignDataService";
import Search from '@/components/Common/Search.vue';
import Pagination from '@/components/Common/Pagination.vue';
import Switcher from '@/components/Common/Switcher.vue';
import BrandService from "../../services/BrandDataService";
import Select from '@/components/Common/Select.vue';

export default {
  name: "ListReferrals",
  data() {
    return {
      Referrals: [],
      headers: [
        { text: "Site", value: "Site" },
        { text: "Name",  value: "Name" },
        { text: "Email", value: "Email" },
        { text: "Referral Code", value: "ReferralCode", sortable: false },
        { text: "Advocate Id", value: "AdvocateId", sortable: false },
        { text: "Selling Company Code", value: "SellingCompany" },
        { text: "Booking Code", value: "BookingCode", sortable: false },        
        { text: "Created Date", value: "CreatedDate" },
        { text: "Booking Actions", value: "booking", sortable: false }
      ],     
      query: "",
      pageIndex: 1,
      totalPages: 0,
      pageSize: 10,
      count: 0,   
      sortBy: "date_dsc",
      role: "",
      loadTable: true,
      brand: "",
      brandItems: [],
      BrandsParams: {
        query : "",
        pageIndex : 0,
        pageSize : 100,
        sortBy : "date_asc"
      }, 
      referralCampaignId : "0",
      referralCampaignItems: [],
      ReferralCampaignsParams: {
        query : "",
        pageIndex : 0,
        pageSize : 100,
        sortBy : "date_asc",
        brand : "*"
      },
      options: {}
    };
  },
  methods: {
    getRequestParams(query, pageIndex, pageSize, brand, referralCampaignId, sortBy, role) {
      let params = {};     
      params["query"] = query;      
      if (pageIndex) {
        params["pageIndex"] = pageIndex - 1;
      }
      if (pageSize) {
        params["pageSize"] = pageSize;
      } 
      params["referralCampaignId"] = referralCampaignId;
      params["brand"] = brand;
      params["sortBy"] = sortBy;
      params["role"] = role;
      return params;
    },
    retrieveReferrals() {
      const params = this.getRequestParams(
        this.query,
        this.pageIndex,
        this.pageSize,
        this.brand,
        this.referralCampaignId,
        this.sortBy,
        this.role
      );
      ReferralService.getAll(params)
        .then((response) => {
          this.Referrals = response.data.data.map(this.getDisplayReferral);
          this.totalPages = Math.ceil(response.data.count/response.data.pageSize,1);
          this.count = response.data.count;
          this.loadTable = false;
          console.log(response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    refreshList() {
      this.retrieveReferrals();
    },
    updateBooking(id) {
      this.$router.push({ name: "UpdateReferral", params: { id: id } });
    },
    getDisplayReferral(Referral) {
      return {
        Id: Referral.id,
        Site: Referral.referralSellingCompany.brand_Code,
        Name: Referral.user.firstName + " "+ Referral.user.lastName,
        Email: Referral.user.emailAddress,
        ReferralCode: Referral.code,
        AdvocateId : Referral.referralAdvocate_Id,
        SellingCompany : Referral.sellingCompany_Code,
        BookingCode : Referral.referralBooking != null ? Referral.referralBooking.code : "",
        CreatedDate : Referral.createdDate
      };
    },
    getBrands() {
      BrandService.getAll(this.BrandsParams)
      .then((response) => {
          let brands = [{text: "All brands", id: "*"}];
          let obj = response.data.data;
          for (var i in obj) {
            brands.push({text: obj[i].name, id: obj[i].code})
          }
          this.brandItems = brands;
        })
        .catch((e) => {
          console.log(e);
        });
    },  
    getReferralCampaigns() {
      this.ReferralCampaignsParams.brand = this.brand;
      ReferralCampaignService.getAll(this.ReferralCampaignsParams)
      .then((response) => {       
          let campaigns = [{text: "All campaigns", id: "0"}];        
          let obj = response.data.data;
          for (var i in obj) {
            campaigns.push({text: obj[i].campaignName, id: obj[i].id.toString()})
          }          
          this.referralCampaignItems = campaigns;        
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  components: {
    Search,
    Pagination,
    Switcher,
    Select
  },
  watch: {
    brand: function (value) {
      this.pageIndex = 1;
      this.loadTable = true;
      this.referralCampaignId = "0"; 
      this.brand = value;
      this.getReferralCampaigns(); 
      this.refreshList();   
    },
    referralCampaignId: function(value) { 
      this.pageIndex = 1;
      this.loadTable = true;
      this.referralCampaignId = value;
      //this.brand = value; 
      this.refreshList();
    },
     options: {
      handler () {
        let sorter = this.options.sortBy[0];
        let sortDir = this.options.sortDesc[0] ? '_dsc' : '_asc';
        if (sorter == undefined) {
          return;
        } else if (sorter === 'CreatedDate') {
          sorter = 'date';
        } else if (sorter === 'SellingCompany') {
          sorter = 'company';
        } else {
          sorter = sorter.toLowerCase();
        }
        this.sortBy = sorter + sortDir;
        this.loadTable = true;
        this.retrieveReferrals();
      }
    }
  }, 
  mounted() {
    this.retrieveReferrals();
    this.getReferralCampaigns();
    this.getBrands();
  }
};
</script>