import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class ReferralEmailTypeDataService {
  getAll(params) {
    return http.get(`/ReferralEmailTypes/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&brand=${params.brand}&sortBy=${params.sortBy}&query=${params.query}`);
  }

  get(id) {
    return http.get(`/ReferralEmailTypes/item/${id}`);
  }

  create(data) {
    return http.post("/ReferralEmailTypes/item/", data);
  }

  update(data) {
    return http.put(`/ReferralEmailTypes/item/`, data);
  }

  delete(id) {
    return http.delete(`/ReferralEmailTypes/item/${id}`);
  }

  find(params) {
    return http.get(`/ReferralEmailTypes/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&brand=${params.brand}&sortBy=${params.sortBy}&query=${params.query}`);
  }
}

export default new ReferralEmailTypeDataService();