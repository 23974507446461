<template>
  <v-app>
    <Header />
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/Common/Header.vue';
export default {
  name: "app",
  components: {
    Header
  }
};
</script>
