import http from "@/http/http-common";
import Auth from '@/app/auth';
export default{  
  setInterceptor(){    
    http.interceptors.request.use(function(config) {
      const auth_token = Auth.auth.getSignInUserSession().getAccessToken().jwtToken;
      if(auth_token) {
        config.headers.Authorization = `Bearer ${auth_token}`;
      }
      return config;
    }, function(err) {
      return Promise.reject(err);
    });
  }  
}