<template>
  <div class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Add Selling Company</p>
    <div v-if="!submitted">
      <Alert :resp="message" />
      <v-form ref="form" lazy-validation>
          <v-row>
              <v-col cols="12" md="6" class="pb-0">
                  <v-text-field v-model="SellingCompany.Code"
                                :rules="[(v) => !!v || 'Code is required']"
                                label="Code"
                                required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
                  <v-text-field v-model="SellingCompany.AccountName"
                                :rules="[(v) => !!v || 'Account Name is required']"
                                label="Account Name"
                                required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <v-text-field v-model="SellingCompany.BusinessUnitLookup"
                                :rules="[(v) => !!v || 'Business Unit Lookup is required']"
                                label="Business Unit Lookup"
                                required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <v-text-field v-model="SellingCompany.PromoCode"
                                label="Promo Code"
                                required></v-text-field>
              </v-col>  <v-col cols="12" md="6" class="pt-0 pb-0">
                  <v-text-field v-model="SellingCompany.NotificationEmail"
                                label="Notification Email"
                                required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <v-text-field v-model="SellingCompany.Address"
                                label="Address"
                                required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <v-text-field v-model="SellingCompany.Phone"
                                label="Phone"
                                required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <v-text-field v-model="SellingCompany.ReferralsURL"
                                label="ReferralsURL"
                                required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <v-text-field type="number"
                                v-model="SellingCompany.CreditLimit"
                                label="Credit Limit"
                                required
                                :min="0"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <Select v-model="SellingCompany.Brand_Code" :selectItems="ddlBrands" label="Brand" />
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <Select v-model="SellingCompany.Region_Code" :selectItems="ddlRegions" label="Region Code" />
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <BackButton />
                  <v-btn color="primary" @click="saveSellingCompany">
                      Add
                  </v-btn>
              </v-col>
          </v-row>
      </v-form>
    </div>

    <div v-else>
      <Alert :resp="{text: 'Submitted successfully!', type: 'success'}" />
      <p>Click the button to add new Selling Company.</p>
      <v-btn color="secondary" to="/SellingCompanies" class="mr-2">Back</v-btn>
      <v-btn color="primary" @click="newSellingCompany">Add</v-btn>
    </div>
  </div>
</template>

<script>
import SellingCompanyService from "../../services/SellingCompanyDataService";
import BrandsService from "../../services/BrandDataService";
import RegionsService from "../../services/RegionDataService";
import BackButton from "@/components/Common/BackButton.vue";
import Alert from '@/components/Common/Alert.vue';
import Select from '@/components/Common/Select.vue';
export default {
  name: "AddSellingCompany",
  data() {
    return {
      SellingCompany: {
        Code: "",
        PromoCode: "",
        NotificationEmail: "",
        AccountName: "",
        BusinessUnitLookup:"",
        Brand_Code: null,
        Region_Code: null
      },
      ddlBrands: [],
      ddlRegions: [],
      params : {
        query : "",
        pageIndex : 0,
        pageSize : 100,
        sortBy : "date_asc"
      },
      submitted: false,
      message: {
        text: "",
        type: ""
      }
    };
  },
  methods: {
    getBrands() {
      BrandsService.getAll(this.params)
        .then((response) => {
          this.ddlBrands =response.data.data.map(function(v){return v.code;});
          console.log(this.ddlBrands);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getRegions() {
      RegionsService.getAll(this.params)
        .then((response) => {
          let region = [{text: "", id: ""}];
          let obj = response.data.data;
          for (var i in obj) {
            region.push({text: obj[i].code, id: obj[i].code}) 
            //ToDo: Nerea show in text  obj[i].code+ " - " +obj[i].currency but send just code isnot working
          }
          this.ddlRegions = region;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    saveSellingCompany() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == false) return;
      var data = {
        Code: this.SellingCompany.Code,
        AccountName: this.SellingCompany.AccountName,
        PromoCode: this.SellingCompany.PromoCode,
        NotificationEmail: this.SellingCompany.NotificationEmail,
        CreditLimit: this.SellingCompany.CreditLimit,    
        BusinessUnitLookup: this.SellingCompany.BusinessUnitLookup,
        Brand_Code: this.SellingCompany.Brand_Code,
        Region_Code: this.SellingCompany.Region_Code,     
        Address: this.SellingCompany.Address,     
        Phone: this.SellingCompany.Phone,  
        ReferralsURL: this.SellingCompany.ReferralsURL                        
      };

      SellingCompanyService.create(data)
        .then((response) => {  
          console.log(response.data);
          this.SellingCompany.Code = response.data.Code;        
          this.submitted = true;
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    newSellingCompany() {
      this.submitted = false;
      this.SellingCompany = {};
    }
  },
  mounted() {
    this.getBrands();
    this.getRegions();
  },
  components: {
    BackButton,
    Alert,
    Select
  }
};
</script>