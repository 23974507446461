<template>
  <v-col class="ml-sm-auto d-flex align-center pa-0" cols="12" sm="6">
    <v-col cols="8" class="ml-sm-auto">
      <v-text-field v-model="search" label="Search" v-on:keyup.enter="handleSearch"></v-text-field>
    </v-col>
    <v-col cols="4" sm="auto">
      <v-btn @click="handleSearch" color="primary">
        Search
      </v-btn>
    </v-col>
  </v-col>
</template>

<script>
export default {
  name: "Search",
  data() {
    return { 
      search: ''
    };
  },
  methods: {
    handleSearch() {
      this.$parent.$data.pageIndex = 1;
      this.$parent.$data.query = this.search;
      this.$parent.$data.loadTable = true;
      this.$parent.refreshList();
    }
  }
};
</script>