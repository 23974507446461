<template>
  <div class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Add Referral Campaigns</p>
    <div v-if="!submitted">
      <Alert :resp="message" />
      <v-form ref="form" lazy-validation>
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              v-model="ReferralCampaign.Code"
              :rules="[(v) => !!v || 'Campaign Code is required']"
              label="Campaign Code"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
            <v-text-field
              v-model="ReferralCampaign.CampaignName"
              :rules="[(v) => !!v || 'Campaign Name is required']"
              label="Campaign Name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <Datepicker @val="datepickerValue" :val.sync="this.ReferralCampaign.ExpirationDate" label="Expiration Date" validation="Expiration Date is required" :minimum="ReferralCampaign.ExpirationDate" />
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <v-text-field
              type="number"
              v-model="ReferralCampaign.CreditAmount"
              label="Credit Amount"
              required
              :min="0"
             ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <v-text-field
              type="number"
              v-model="ReferralCampaign.DiscountAmount"
               label="Discount Amount"
              required
              :min="0"
             ></v-text-field>
          </v-col>            
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <Select v-model="ReferralCampaign.SellingCompany_Code" :selectItems="dropdownItems" label="Selling Company" />
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <BackButton />
            <v-btn color="primary" @click="saveReferralCampaigns">
              Add
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div v-else>
      <Alert :resp="{text: 'Submitted successfully!', type: 'success'}" />
      <p>Click the button to add new Referral Campaign.</p>
      <v-btn color="secondary" to="/ReferralCampaigns" class="mr-2">Back</v-btn>
      <v-btn color="primary" @click="newReferralCampaign">Add</v-btn>
    </div>
  </div>
</template>

<script>
import ReferralCampaignService from "../../services/ReferralCampaignDataService";
import SellingCompanyDataService from "../../services/SellingCompanyDataService";
import BackButton from "@/components/Common/BackButton.vue";
import Datepicker from "@/components/Common/Datepicker.vue";
import Alert from '@/components/Common/Alert.vue';
import Select from '@/components/Common/Select.vue';
export default {
  name: "AddReferralCampaign",
  data() {
    return {
      ReferralCampaign: {
        Code: "",
        Name: "",
        ExpirationDate: new Date().toISOString().substr(0, 10),
        CreditAmount: 0,
        DiscountAmount: 0,
        CreditLimit: 0,
        SellingCompany_Code : null
      },
      dropdownItems: [],
      params : {
        query : "",
        pageIndex : 0,
        pageSize : 100,
        sortBy : "date_asc",
        brand : "*"
      },
      submitted: false,
      message: {
        text: "",
        type: ""
      }
    };
  },
  methods: {
     getSellingCompanies() {  
      SellingCompanyDataService.getAll(this.params)
        .then((response) => {
          this.dropdownItems =response.data.data.map(function(v){return v.code;});
          console.log(this.dropdownItems);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    saveReferralCampaigns() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == false) return;
      var data = {
        Code: this.ReferralCampaign.Code,
        CampaignName: this.ReferralCampaign.CampaignName,
        ExpirationDate: this.ReferralCampaign.ExpirationDate,
        CreditAmount: this.ReferralCampaign.CreditAmount,     
        DiscountAmount: this.ReferralCampaign.DiscountAmount, 
        SellingCompany_Code : this.ReferralCampaign.SellingCompany_Code
      };

      ReferralCampaignService.create(data)
        .then((response) => {  
          console.log(response.data);
          this.ReferralCampaign.CampaignId = response.data.CampaignId;        
          this.submitted = true;
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    newReferralCampaign() {
      this.submitted = false;
      this.ReferralCampaign = {};
    },
    datepickerValue(value) {
      this.ReferralCampaign.ExpirationDate = value;
    }
  },
  mounted() {
    this.getSellingCompanies();
  },
  components: {
    BackButton,
    Datepicker,
    Alert,
    Select
  }
};
</script>