<template>
  <div class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Upload CSV File</p>
    <Alert :resp="message" />

    <div class="introduction">
      <p>
        Welcome to the referral upload page. Here you can upload a CSV file to add multiple referrals at once.
        Please make sure your CSV file is formatted correctly with the following columns:
      </p>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>CSV Fields</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="fields-container">
              <div class="fields-column">
                <ul>
                  <li>ContactId - Salesforce Contact Id</li>
                  <li>FirstName - *required, 40 character limit</li>
                  <li>LastName - *required, 80 character limit</li>
                  <li>EmailAddress - *required, needs to be a valid email</li>
                  <li>LastBookingId - 20 character limit</li>
                  <li>SellingCompany - *required, 20 character limit</li>
                </ul>
              </div>
              <div class="fields-column">
                <ul>
                  <li>AgencyCode - if code exists, link to referral</li>
                  <li>AgencyName - if AgencyCode does not exist, add AgencyName</li>
                  <li>AgencyEmailAddress - if AgencyCode does not exist, add AgencyEmailAddress</li>
                  <li>AgencyPhone - if AgencyCode does not exist, add AgencyPhone</li>
                  <li>AgencyConsultantName - if AgencyCode does not exist, add AgencyConsultantName</li>
                </ul>
              </div>
              <div class="fields-column">
                <ul>
                  <li>Market - *required, 2 characters</li>
                  <li>SellingCompany - *required, 20 character limit</li>
                  <li>BusinessUnitLookupValue - *required, 100 character limit</li>
                  <li>CodePrefix - Optional</li>
                  <li>Referral1FirstName - *required, 40 character limit</li>
                  <li>Referral1LastName - *required, 80 character limit</li>
                  <li>Referral1EmailAddress - *required, needs to be a valid email</li>
                  <li>PersonalMessage - Optional</li>
                  <li>CampaignCode - *required</li>
                  <li>Website - *required, Uniworld, IV, LG, Trafalgar, Contiki</li>
                </ul>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <p>
        Each referral row can include up to 10 individual referrals. Make sure all email addresses are unique and valid.
        After selecting your CSV file, click the "Upload" button to proceed.
      </p>
      <p>
        You can download a sample CSV file <a href="/Referrals.csv" download>here</a>.
      </p>
    </div>

    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <v-file-input
            v-model="file"
            label="CSV File"
            accept=".csv"
            :rules="[v => !!v || 'CSV file is required']"
            required
          ></v-file-input>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn color="primary" @click="confirmUpload">Upload</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row v-if="results.length > 0">
      <v-col cols="12">
        <h3>Upload Results</h3>
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="results" class="elevation-1">
          <!-- eslint-disable-next-line -->
          <template #item.success="{ item }">
            <v-icon :color="item.success ? 'green' : 'red'">
              {{ item.success ? 'mdi-check' : 'mdi-close' }}
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Confirm Upload</v-card-title>
        <v-card-text>
          Are you sure you want to upload this CSV file and add {{ totalRows }} referrals?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="uploadCsv">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ReferralFileService from "@/services/ReferralFileService";
import Alert from '@/components/Common/Alert.vue';

export default {
  name: "UploadCsv",
  components: {
    Alert,
  },
  data() {
    return {
      file: null,
      message: {
        text: "",
        type: ""
      },
      results: [],
      dialog: false,
      totalRows: 0,
      headers: [
        { text: 'Row Number', value: 'rowNumber' },
        { text: 'Success', value: 'success' },
        { text: 'Message', value: 'message' }
      ]
    };
  },
  methods: {
    confirmUpload() {
      if (this.$refs.form.validate()) {
        this.calculateTotalRows();
        this.dialog = true;
      }
    },
    calculateTotalRows() {
      const reader = new FileReader();
      reader.onload = (e) => {
        const lines = e.target.result.split('\n');
        this.totalRows = lines.length - 1; // Subtract 1 for the header row
      };
      reader.readAsText(this.file);
    },
    async uploadCsv() {
      this.dialog = false;
      try {
        const response = await ReferralFileService.uploadCsv(this.file);
        this.results = response.data;
        this.message.text = `File uploaded successfully! Total rows processed: ${this.totalRows}.`;
        this.message.type = "success";
      } catch (error) {
        console.error(error);
        this.message.text = "Failed to upload file.";
        this.message.type = "error";
      }
    }
  }
};
</script>

<style>
.introduction {
  margin-bottom: 20px;
}

.introduction p {
  margin: 10px 0;
}

.fields-container {
  display: flex;
  justify-content: space-between;
}

.fields-column {
  flex: 1;
  margin-right: 20px;
}

.fields-column ul {
  list-style-type: disc;
  padding-left: 20px;
}

.fields-column ul li {
  margin: 5px 0;
}
</style>
