import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class ReferralEmailDataService {
  getAll(params) {
    return http.get(`/ReferralEmails/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&brand=${params.brand}&sortBy=${params.sortBy}&query=${params.query}`);
  }

  get(id) {
    return http.get(`/ReferralEmails/item/${id}`);
  }

  create(data) {
    return http.post("/ReferralEmails/item/", data);
  }

  update(data) {
    return http.put(`/ReferralEmails/item/`, data);
  }

  delete(id) {
    return http.delete(`/ReferralEmails/item/${id}`);
  }

  find(params) {
    return http.get(`/ReferralEmails/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&brand=${params.brand}&sortBy=${params.sortBy}&query=${params.query}`);
  }
}

export default new ReferralEmailDataService();