<template>
  <v-select
    v-if="hasValue"
    v-model="currentData"
    :items="selectItems"
    item-value="id"
    item-text="text"
    :rules="[(v) => !!v || label + ' is required']"
    :label="label"
    multiple
    required
  ></v-select>
  <v-select
    v-else
    v-model="currentData"
    :items="selectItems"
    :rules="[(v) => !!v || label + ' is required']"
    :label="label"
    multiple
    required
  ></v-select>
</template>

<script>
export default {
  name: "MultiSelect", // Renaming to distinguish this as a multi-select
  data() {
    return { 
      currentData: this.val // Set currentData to initial value of val (expected array)
    }
  },
  props: {
    val: {
      type: Array, // Now handling an array for multiple selections
      default: () => [] // Default to empty array if not provided
    },
    selectItems: Array, // Items to display in the select
    label: String, // Label for the select input
    hasValue: Boolean // Boolean to control rendering logic
  },
  watch: {
    currentData(value) {
      this.$emit('input', value); // Emit the selected values as an array
    },
    val() {  
      this.currentData = this.val; // Sync currentData with the val prop if it changes externally
    }
  }
};
</script>
