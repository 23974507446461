<template>
  <div v-if="currentSellingCompany" class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Edit Selling Company</p>
    <Alert :resp="message" />
    <v-form ref="form" lazy-validation>
        <v-row>
            <v-col cols="12" md="6" class="pb-0">
                <v-text-field v-model="currentSellingCompany.code"
                              :rules="[(v) => !!v || 'Code is required']"
                              label="Code"
                              required
                              disabled></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
                <v-text-field v-model="currentSellingCompany.accountName"
                              :rules="[(v) => !!v || 'Account Name is required']"
                              label="Account Name"
                              required></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-text-field v-model="currentSellingCompany.businessUnitLookup"
                              :rules="[(v) => !!v || 'Business Unit Lookup is required']"
                              label="Business Unit Lookup"
                              required></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-text-field v-model="currentSellingCompany.promoCode"
                              label="Promo Code"
                              required></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-text-field v-model="currentSellingCompany.notificationEmail"
                              label="Notification Email"
                              required></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-text-field v-model="currentSellingCompany.address"
                              label="Address"
                              required></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-text-field v-model="currentSellingCompany.phone"
                              label="Phone"
                              required></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-text-field v-model="currentSellingCompany.referralsURL"
                              label="ReferralsURL"
                              required></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-text-field type="number"
                              v-model="currentSellingCompany.creditLimit"
                              label="Credit Limit"
                              required
                              :min="0"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <Select v-model="currentSellingCompany.brand_Code" :val="currentSellingCompany.brand_Code" :selectItems="ddlBrands" label="Brand" />
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
                <Select v-model="currentSellingCompany.region_Code" :val="currentSellingCompany.region_Code" :selectItems="ddlRegions" label="Currency" />
            </v-col>
            <v-col cols="12" md="6">
                <BackButton />
                <v-btn color="error" class="mr-2" @click="$refs.modal.dialog = true">
                    Delete
                </v-btn>
                <v-btn color="primary" @click="updateSellingCompany">
                    Update
                </v-btn>
                <Dialog text="Selling Company" verb="delete" ref="modal" />
            </v-col>
        </v-row>
    </v-form>
  </div>

  <div v-else-if="deletedCompany" class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Edit Selling Company</p>
    <Alert :resp="message" />
  </div>

  <div v-else class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <Loading text="Selling Company" />
  </div>
</template>

<script>
import SellingCompanieservice from "../../services/SellingCompanyDataService";
import BrandsService from "../../services/BrandDataService";
import RegionsService from "../../services/RegionDataService";
import BackButton from "@/components/Common/BackButton.vue";
import Loading from "@/components/Common/Loading.vue";
import Dialog from '@/components/Common/Dialog.vue';
import Alert from '@/components/Common/Alert.vue';
import Select from '@/components/Common/Select.vue';
export default {
  name: "UpdateSellingCompany",
  data() {
    return {
      currentSellingCompany: null,
      deletedCompany: false,
      message: {
        text: "",
        type: ""
      },
      ddlBrands: [],
      ddlRegions: [],
      params : {
        query : "",
        pageIndex : 0,
        pageSize : 100,
        sortBy : "date_asc"
      }
    };
  },
  methods: {
    getBrands() {
      BrandsService.getAll(this.params)
        .then((response) => {
          this.ddlBrands =response.data.data.map(function(v){return v.code;});
          console.log(this.ddlBrands);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getRegions() {
      RegionsService.getAll(this.params)
        .then((response) => {
          let region = [{text: "", id: ""}];
          let obj = response.data.data;
          for (var i in obj) {
            region.push({text: obj[i].code, id: obj[i].code}) 
            //ToDo: Nerea show in text  obj[i].code+ " - " +obj[i].currency but send just code isnot working
          }
          this.ddlRegions = region;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getSellingCompany(code) {
      SellingCompanieservice.get(code)
        .then((response) => {
          this.currentSellingCompany = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateSellingCompany() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == false) return;
      this.currentSellingCompany.region = null;
      SellingCompanieservice.update(this.currentSellingCompany)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The SellingCompany was updated successfully!";
          this.message.type = "success";
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    dialogData() {
      SellingCompanieservice.delete(this.currentSellingCompany.code)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The SellingCompany was deleted successfully!";
          this.message.type = "success";
          this.currentSellingCompany = null;
          this.deletedCompany = true;
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    }
  },
  mounted() {
    this.getSellingCompany(this.$route.params.code);
    this.getBrands();
    this.getRegions();
  },
  components: {
    BackButton,
    Loading,
    Dialog,
    Alert,
    Select
  }
};
</script>