<template>
  <div>
    <p v-if="showText">Please click on a {{text}}...</p>
    <v-overlay v-model="overlay">
      <v-progress-circular
        indeterminate
        size="55"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return { 
      overlay: true,
      showText: false
    }
  },
  props: {
    'text': String
  },
  mounted() {
    if (this.overlay) {
      var self = this;
      window.setTimeout(() => {
        self.overlay = false;
        self.showText = true;
      }, 3000)
    }
  },
};
</script>