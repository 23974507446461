<template>
  <v-row align="center">
    <v-col cols="12" class="mt-3 pb-0"> 
      <h1>Referrals Report</h1>
    </v-col>
    <div class="col-sm-8 col-md-10 col-lg-8 mx-auto">
      <div class="text-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="#032747"
          indeterminate
          class="mt-10 mb-10"
          v-if="!this.graphValues.length"
        ></v-progress-circular>
        <v-sparkline
          :value="graphValues"
          :labels="graphLabels"
          :show-labels="true"
          label-size="4"
          :gradient="gradient"
          smooth="10"
          padding="5"
          line-width="2"
          stroke-linecap="round"
          gradient-direction="top"
          :fill="false"
          type="trend"
          auto-draw
        >
          <template v-slot:label="item">
            {{ item.value }}
          </template>
        </v-sparkline>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" md="6" class="pb-0 pt-10">
              <Datepicker @val="datepickerValueStart" :val.sync="this.startDate" label="Start Date" validation="Start Date is required" />
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-10">
              <Datepicker @val="datepickerValueEnd" :val.sync="this.endDate" label="End Date" validation="End Date is required" />
            </v-col>
            <!-- Multi-select for Brands -->
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <MultiSelect
                v-model="brands"
                :selectItems="brandItems"
                label="Brands"
                :hasValue="true"
              />
            </v-col>                        
            <!-- Multi-select for Referral Campaigns -->
            <v-col cols="12" md="6" class="pb-0 pt-0">
              <MultiSelect
                v-model="campaigns"
                :selectItems="referralCampaignItems"
                label="Referral Campaign"
                :hasValue="brands.length > 0"
              />
            </v-col>
            <v-col cols="12" class="pb-10 text-center">
              <v-btn-toggle
                v-model="periodicity"
                dense
                mandatory
              >
                <v-btn value="none">All</v-btn>
                <v-btn value="daily">Daily</v-btn>
                <v-btn value="weekly">Weekly</v-btn>
                <v-btn value="monthly">Monthly</v-btn>
              </v-btn-toggle>
            </v-col>          
          </v-row>
        </v-form>
      </div>
    </div>
    <v-col cols="12" class="pt-0">
      <v-data-table
        :headers="headers"
        :items="Reports"
        disable-pagination
        class="elevation-2"
        :loading="loadTable"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :server-items-length="count"
        :hide-default-footer="true"
      ></v-data-table>
    </v-col>
    <Pagination :count="count" :totalPages="totalPages" :pageSize="pageSize" :pageIndex="pageIndex" />
  </v-row>
</template>

<script>
import BrandService from "../../services/BrandDataService";
import ReferralCampaignService from "../../services/ReferralCampaignDataService";
import ReportDataService from "../../services/ReportDataService";
import Pagination from '@/components/Common/Pagination.vue';
import Datepicker from "@/components/Common/Datepicker.vue";
import MultiSelect from "@/components/Common/MultiSelect.vue";

export default {
  data() {
    return {
      Reports: [],
      headers: [
        { text: "Start Date", value: "startDate", sortable: false },
        { text: "End Date", value: "endDate", sortable: false },
        { text: "Total", value: "referralsTotal", sortable: false },
        { text: "Referrals With Bookings", value: "referralsWithBooking", sortable: false },
        { text: "Referrals Without Bookings", value: "referralsWithOutBooking", sortable: false },
        { text: "Referral Bookings Paid in Full", value: "referralsPaidInFullBooking", sortable: false },
        { text: "Referral Guests Paid In Full", value: "referralsGuestPaidInFull", sortable: false },
        { text: "Referrals with a personal message", value: "referralsPersonalMessage", sortable: false }
      ],
      query: "",
      pageIndex: 1,
      totalPages: 0,
      pageSize: 10,
      count: 0,
      campaigns: [],  
      brands: [],         
      brandItems: [],   
      referralCampaignItems: [],
      startDate: new Date(new Date().getFullYear(), 0, 1).toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      periodicity: "none",
      gradient: ['#032747', '#032747', '#e6e6e6', '#d0d0d0'],
      graphValues: [],
      graphLabels: [],
      loadTable: true,
      options: {},
      BrandsParams: {
        query: "",
        pageIndex: 0,
        pageSize: 100,
        sortBy: "date_asc"
      },
      ReferralCampaignsParams: {
        query: "",
        pageIndex: 0,
        pageSize: 100,
        sortBy: "date_asc",
        brands: "*"
      },
      ReportParams: {
        query: "",
        pageIndex: 0,
        pageSize: 100,
        brands: "*",
        campaigns: "*",
		startDate: new Date(new Date().getFullYear(), 0, 1).toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        periodicity: "none",
      }
    };
  },
  methods: {
    getBrands() {
      // Fetch brands from API
      BrandService.getAll(this.BrandsParams)
        .then((response) => {
          let brandsDDL = [{ text: "All Brands", id: "*" }];
          response.data.data.forEach((brand) => {
            brandsDDL.push({ text: brand.name, id: brand.code });
          });
          this.brandItems = brandsDDL;
        })
        .catch((error) => {
          console.error("Error loading brands:", error);
        });
    },
    getReferralCampaigns() {
      // Update brands for the ReferralCampaignsParams
      this.ReferralCampaignsParams.brands = this.brands.length ? this.brands.join(',') : "*";
      ReferralCampaignService.getAll(this.ReferralCampaignsParams)
        .then((response) => {
          let campaignsDDL = [{ text: "All campaigns", id: "0" }]; 
          response.data.data.forEach((campaign) => {
            campaignsDDL.push({ text: campaign.campaignName, id: campaign.id.toString() });
          });
          this.referralCampaignItems = campaignsDDL; 
        })
        .catch((error) => {
          console.error("Error loading campaigns:", error);
        });
    },    
    getReportParams() {
      this.ReportParams.query= this.query;
      this.ReportParams.pageIndex= this.pageIndex - 1;
      this.ReportParams.pageSize= this.pageSize;
      this.ReportParams.brands= this.brands && this.brands.length > 0 ? this.brands.join(',') : "*";
      this.ReportParams.campaigns= this.campaigns && this.campaigns.length > 0 ? this.campaigns.join(',') : "0";
      this.ReportParams.startDate= this.startDate;
      this.ReportParams.endDate= this.endDate;
      this.ReportParams.periodicity= this.periodicity;
    }, 
    retrieveReports() {
      this.getReportParams();
      ReportDataService.getReport(this.ReportParams)
        .then((response) => {
          this.Reports = response.data.data.map(this.getDisplayReport);
          this.totalPages = Math.ceil(response.data.count / response.data.pageSize);
          this.count = response.data.count;
          this.loadTable = false;
        })
        .catch((error) => {
          console.error("Error loading reports:", error);
        });
    },
    retrieveGraph() {
      this.getReportParams();
      this.ReportParams.pageSize= 1000;
      this.ReportParams.periodicity= 'monthly';
      ReportDataService.getReport(this.ReportParams)
        .then((response) => {
          this.graphValues = response.data.data.map((r) => r.referralsTotal);
          this.graphLabels = response.data.data.map((r) => r.startDate.slice(0, 7));
        })
        .catch((error) => {
          console.error("Error loading graph data:", error);
        });
    },
    refreshReport() {
      this.retrieveReports();
      this.retrieveGraph();
    },
    datepickerValueStart(value) {
      this.startDate = value;
      this.refreshReport();
    },
    datepickerValueEnd(value) {
      this.endDate = value;
      this.refreshReport();
    },
    getDisplayReport(Report) {
      return {
        startDate: Report.startDate.substr(0, 10),
        endDate: Report.endDate.substr(0, 10),
        referralsTotal: Report.referralsTotal,
        referralsWithBooking: Report.referralsWithBooking,
        referralsWithOutBooking: Report.referralsWithOutBooking,
        referralsPaidInFullBooking: Report.referralsPaidInFullBooking,
        referralsPersonalMessage: Report.referralsPersonalMessage,
        referralsGuestPaidInFull: Report.referralsGuestPaidInFull
      };
    }
  },
  components: {
    Pagination,
    Datepicker,
    MultiSelect 
  },
  watch: {
    periodicity: function (value) {
      this.pageIndex = 1;
      this.loadTable = true;
      this.periodicity = value;
      this.refreshReport();
    },
    brands: function (value) {
      this.pageIndex = 1;
      this.loadTable = true;
      this.campaigns = [];
      this.brands = value;
      this.getReferralCampaigns(); 
      this.refreshReport();
    },
    campaigns: function(value) {
      this.pageIndex = 1;
      this.loadTable = true;
      this.campaigns = value;
      this.refreshReport();
    },
    options: {
      handler () {
        let sorter = this.options.sortBy[0];
        let sortDir = this.options.sortDesc[0] ? '_dsc' : '_asc';
        if (sorter == undefined) {
          return;
        } else if (sorter === 'CreatedDate') {
          sorter = 'date';
        } else if (sorter === 'SellingCompany') {
          sorter = 'company';
        } else {
          sorter = sorter.toLowerCase();
        }
        this.sortBy = sorter + sortDir;
        this.loadTable = true;
        this.retrieveReports();
      }
    }
  },
  mounted() {
    this.getBrands();
    this.getReferralCampaigns(); 
    this.retrieveReports();
    this.retrieveGraph();  
  }
};
</script>
