<template>
  <div class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Add Brand</p>
    <div v-if="!submitted">
      <v-form ref="form" lazy-validation>
          <v-row>
              <v-col cols="12" md="6" class="pb-0">
                  <v-text-field v-model="Brand.Code"
                                :rules="[(v) => !!v || 'Code is required']"
                                label="Code"
                                required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
                  <v-text-field v-model="Brand.Name"
                                :rules="[(v) => !!v || 'Name is required']"
                                label="Name"
                                required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <v-text-field type="number"
                                v-model="Brand.CreditExpirationMonths"
                                :rules="[(v) => !!v || 'Credit Expiration Months is required']"
                                label="Credit Expiration Months"
                                required
                                :min="1"
                                oninput="validity.valid||(value='');"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <v-text-field type="number"
                                v-model="Brand.ReferralCodeExpirationMonths"
                                :rules="[(v) => !!v || 'Referral Code Expiration Months is required']"
                                label="Referral Code Expiration Months"
                                required
                                :min="1"
                                oninput="validity.valid||(value='');"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <v-text-field type="number"
                                v-model="Brand.BrandGroup"
                                :rules="[(v) => !!v || 'Brand Group is required']"
                                label="Brand Group"
                                required
                                :min="1"
                                oninput="validity.valid||(value='');"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
                  <v-text-field v-model="Brand.URL"
                                label="URL"
                                required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                  <BackButton />
                  <v-btn color="primary" @click="saveBrand">
                      Add
                  </v-btn>
              </v-col>
          </v-row>
      </v-form>
    </div>

    <div v-else>
      <Alert :resp="{text: 'Submitted successfully!', type: 'success'}" />
      <p>Click the button to add new Brand.</p>
      <v-btn color="secondary" to="/Brands" class="mr-2">Back</v-btn>
      <v-btn color="primary" @click="newBrand">Add</v-btn>
    </div>
  </div>
</template>

<script>
import BrandsService from "../../services/BrandDataService";
import BackButton from "@/components/Common/BackButton.vue";
import Alert from '@/components/Common/Alert.vue';

export default {
  name: "AddBrand",
  data() {
    return {
      Brand: {
        Code: "",
        Name: "",
        CreditExpirationMonths: 0,
        ReferralCodeExpirationMonths: 0,
        BrandGroup: 0,
      },
      dropdownItems: [],
      params : {
        query : "",
        pageIndex : 0,
        pageSize : 10,
        sortBy : "date_asc"
      },
      submitted: false,
    };
  },
  methods: {
    saveBrand() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == false) return;
      var data = {
        Code: this.Brand.Code,
        Name: this.Brand.Name,
        SenderEmail: this.Brand.SenderEmail,
        CreditExpirationMonths: this.Brand.CreditExpirationMonths,
        ReferralCodeExpirationMonths: this.Brand.ReferralCodeExpirationMonths,    
        BrandGroup: this.Brand.BrandGroup,
        URL: this.Brand.URL
      };

      BrandsService.create(data)
        .then((response) => {  
          console.log(response.data);
          this.Brand.Code = response.data.Code;        
          this.submitted = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    newBrand() {
      this.submitted = false;
      this.Brand = {};
    }
  },
  components: {
    BackButton,
    Alert
  }
};
</script>