import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class ReferralCreditDataService {
  getAllByAdvocateId(params) {
    return http.get(`/ReferralCredits/items?advocateId=${params.id}&pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&sortBy=${params.sortBy}&query=${params.query}`);
  }

  redeem(id) {
    return http.put(`/ReferralCredits/item/${id}/Redeem`);
  }

  find(params) {
    return http.get(`/ReferralCredits/items?advocateId=${params.advocateId}&pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&sortBy=${params.sortBy}&query=${params.query}`);
  }

  get(id) {
    return http.get(`/ReferralCredits/item/${id}`);
  }

  partialredeem(credit) {
    return http.put(`/ReferralCredits/item/${credit.id}/Redeem/${credit.toRedeem}`, credit);
  }

  update(credit) {
    return http.put(`/ReferralCredits/item/`, credit);
  }
}

export default new ReferralCreditDataService();