<template>
  <v-alert
    dense
    :type="resp.type"
    v-if="resp.text != ''"
    :class="css"
    transition="fade-transition">
    {{resp.text}}
  </v-alert>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return { 
      alert: false
    }
  },
  props: {
    'resp': Object,
    'css': String
  }
};
</script>