<template>
  <div>
    <!-- Desktop nav -->
    <v-app-bar app dark dense color="primary" class="d-none d-lg-block">
      <v-container>
        <v-row no-gutters align="center">
          <v-toolbar-title class="col-auto mr-lg-3">
            <router-link to="/" class="header-link">TTC - Referrals - V 0.1</router-link>
          </v-toolbar-title>
          <div class="col-auto" v-if="$store.getters.loggedIn">
            <v-btn
              v-for="(item, index) in nav"
              :key="index"
              :to="item.href"
              text
              v-show="item.showLink"
              class="nav-btn"
            >
              {{ item.text }}
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            :to="login.logoutHref"
            outlined
            v-if="$store.getters.loggedIn"
            class="col-auto"
          >
            {{ login.logoutText }}
          </v-btn>
          <v-btn
            :to="login.loginHref"
            outlined
            v-else
            class="col-auto"
          >
            {{ login.loginText }}
          </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>

    <!-- Mobile nav -->
    <v-app-bar color="primary" app dark dense class="d-lg-none">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title>TTC - Referrals - V 0.1</v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="primary--text text--accent-4"
        >
          <div v-if="$store.getters.loggedIn">
            <v-list-item
              v-for="(item, index) in nav"
              :key="index"
              :to="item.href"
              v-show="item.showLink"
              class="nav-list-item"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </div>
          <v-list-item :to="login.logoutHref" v-if="$store.getters.loggedIn">
            <v-list-item-title>{{ login.logoutText }}</v-list-item-title>
          </v-list-item>
          <v-list-item :to="login.loginHref" v-else>
            <v-list-item-title>{{ login.loginText }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "Header",
  data: function() {
    return {
      drawer: false,
      group: null,
      nav: [
        {
          text: 'Referrals',
          href: '/Referrals',
          showLink: true
        },
        {
          text: 'Referral Advocates',
          href: '/ReferralAdvocates',
          showLink: true
        },
        {
          text: 'Referral Campaigns',
          href: '/ReferralCampaigns',
          showLink: this.$store.getters.admin
        },
        {
          text: 'Selling Companies',
          href: '/SellingCompanies',
          showLink: this.$store.getters.admin
        },
        {
          text: 'Brands',
          href: '/Brands',
          showLink: this.$store.getters.admin
        }
      ],
      login: {
        loginText: 'Login',
        loginHref: '/login',
        logoutText: 'Logout',
        logoutHref: '/logout'
      }
    };
  }
};
</script>

<style scoped>
.header-link {
  color: white;
  text-decoration: none;
}

.nav-btn {
  transition: background-color 0.3s ease;
}

.nav-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-list-item {
  transition: background-color 0.3s ease;
}

.nav-list-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.v-app-bar-nav-icon {
  cursor: pointer;
}
</style>
