<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col">
        <h3 class="mb-4">Welcome, {{$store.getters.cognitoInfo.username}}</h3>
        <div v-if="!$store.getters.resAgent">
          <v-btn
            color="primary"
            to="/CreditReports"
            class="mr-3 mb-3"
          >
            Credit Reports
          </v-btn>
          <v-btn
            color="primary"
            to="/ReferralReports"
            class="mr-3 mb-3"
          >
            Referral Reports
          </v-btn>
          <v-btn
            color="primary"
            to="/Referrals/upload"
            class="mb-3"
          >
            Upload Referrals
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    if (!localStorage.getItem("reloaded")) {
      localStorage.setItem("reloaded", "1");
      this.$router.go();
    }
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: auto;
}

h3 {
  font-weight: bold;
}

.v-btn {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.v-btn:hover {
  background-color: #1976d2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
