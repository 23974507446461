<template>
  <v-menu
    v-model="datepicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="currentDate"
        :label="label"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        v-on:click:append="datepicker=true"
        :rules="[(v) => !!v || validation]"
        required
      ></v-text-field>
    </template>
    <v-date-picker v-model="currentDate"
      no-title
      scrollable
      @input="datepicker = false"
      :min="minimum">
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "Datepicker",
  data() {
    return { 
      datepicker: false,
      currentDate: this.val
    }
  },
  props: {
    'val': String,
    'label': String,
    'validation': String,
    'minimum': String
  },
  watch: {
    currentDate: function (value) {
      this.$emit('val', value);
    }
  }
};
</script>