<template>
  <v-row align="center">
    <v-col cols="12" class="mt-3 pb-0"> 
      <h1>Referral Campaigns</h1>
      <Alert :resp="message" css="mt-5" />
    </v-col>
    <v-col cols="12" class="mt-3 pb-0 pl-0"> 
       <AddButton link="/ReferralCampaign/Add" />
    </v-col>
    <v-col cols="12" md="6" class="pb-0 pt-0">
      <Select v-model="brandCode" :val="brandCode" :selectItems="dropdownItems" label="Brands" :hasValue="true" />        
    </v-col>
    <Search />
    <v-col cols="12" class="pt-0">
      <v-data-table
        :headers="headers"
        :items="ReferralCampaigns"
        disable-pagination
        class="elevation-2"
        :loading="loadTable"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :server-items-length="count"
        :hide-default-footer="true"
      >
        <template v-slot:[`item.SellingCompany_Code`]="{ item }">
          <router-link :to="{ name: 'UpdateSellingCompany', params: { code: item.SellingCompany_Code } }">
            {{ item.SellingCompany_Code }}
          </router-link>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editReferralCampaign(item.Id)">mdi-pencil</v-icon>
          <v-icon small @click="openDialog(item.Id)">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <Dialog text="Referral Campaign" verb="delete" ref="modal" />
    </v-col>
    <Pagination :count="count" :totalPages="totalPages" :pageSize="pageSize" :pageIndex="pageIndex" />
  </v-row>
</template>

<script>
import ReferralCampaignService from "../../services/ReferralCampaignDataService";
import Search from '@/components/Common/Search.vue';
import AddButton from '@/components/Common/AddButton.vue';
import Pagination from '@/components/Common/Pagination.vue';
import Dialog from '@/components/Common/Dialog.vue';
import Alert from '@/components/Common/Alert.vue';
import BrandService from "../../services/BrandDataService";
import Select from '@/components/Common/Select.vue';

export default {
  name: "ListReferralCampaigns",
  data() {
    return {
      ReferralCampaigns: [],
      headers: [
        { text: "Id", value: "Id", sortable: false },
        { text: "Campaign Code", value: "CampaignCode" },
        { text: "Campaign Name", value: "CampaignName" },
        { text: "Expiration Date", value: "ExpirationDate", sortable: false },     
        { text: "Credit Amount", value: "CreditAmount", sortable: false },
        { text: "Discount Amount", value: "DiscountAmount", sortable: false },

        { text: "SellingCompanyCode", value: "SellingCompany_Code", sortable: false },
        { text: "Created Date", value: "CreatedDate" },
        { text: "Actions", value: "actions", sortable: false },
      ], 
      brand: "",
      query: "",
      pageIndex: 1,
      totalPages: 0,
      pageSize: 10,
      count: 0,
      sortBy: "date_dsc",
      loadTable: true,
      brandCode: "*",
      BrandsParams: {
        query : "",
        pageIndex : 0,
        pageSize : 100,
        sortBy : "date_asc"
      },
      dropdownItems: [],
      options: {},
      message: {
        text: "",
        type: ""
      }
    };
  },
  methods: {
    getRequestParams(brand,query, pageIndex, pageSize, sortBy) {
      let params = {};
      params["query"] = query;
      if (pageIndex) {
        params["pageIndex"] = pageIndex - 1;
      }
      if (pageSize) {
        params["pageSize"] = pageSize;
      }
      if (sortBy) {
        params["sortBy"] = sortBy;
      }
      params["brand"] = brand;
      return params;
    },
    retrieveReferralCampaigns() {
      const params = this.getRequestParams(
        this.brandCode,  
        this.query,
        this.pageIndex,
        this.pageSize,
        this.sortBy
      );
      ReferralCampaignService.getAll(params)
      .then((response) => {
        this.ReferralCampaigns = response.data.data.map(this.getDisplayReferralCampaign);
        this.totalPages = Math.ceil(response.data.count/response.data.pageSize,1);
        this.count = response.data.count;
        this.loadTable = false;
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    }, 
    refreshList() {
      this.retrieveReferralCampaigns();
    },
    editReferralCampaign(id) {
      this.$router.push({ name: "UpdateReferralCampaign", params: { id: id } });
    },
    getDisplayReferralCampaign(ReferralCampaign) {
      return {
        Id: ReferralCampaign.id,
        CampaignCode: ReferralCampaign.code,
        CampaignName: ReferralCampaign.campaignName,
        ExpirationDate:ReferralCampaign.expirationDate,
        CreditAmount: ReferralCampaign.creditAmount.toFixed(2),
        DiscountAmount: ReferralCampaign.discountAmount.toFixed(2),
        CreatedDate: ReferralCampaign.createdDate,
        SellingCompany_Code : ReferralCampaign.sellingCompany_Code
      };
    },
    openDialog(id) {
      this.$refs.modal.dialog = true;
      this.id = id;
    },
    dialogData() {
      ReferralCampaignService.delete(this.id)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The ReferralCampaign was deleted successfully!";
          this.message.type = "success";
          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    getBrands() {
      BrandService.getAll(this.BrandsParams)
      .then((response) => {
        let brands = [{text: "All brands", id: "*"}];
          let obj = response.data.data;
          for (var i in obj) {
            brands.push({text: obj[i].name, id: obj[i].code})
          }
          this.dropdownItems = brands;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  components: {
    Search,
    AddButton,
    Pagination,
    Dialog,
    Alert,
    Select
  },
  watch: {
    brandCode: function(value) {
      this.pageIndex = 1;
      this.loadTable = true;
      this.brandCode = value;
      this.retrieveReferralCampaigns();
    },
    options: {
      handler () {
        let sorter = this.options.sortBy[0];
        let sortDir = this.options.sortDesc[0] ? '_dsc' : '_asc';
        if (sorter == undefined) {
          return;
        } else if (sorter === 'CampaignName') {
          sorter = 'name';
        } else if (sorter === 'CreatedDate') {
          sorter = 'date';
        } else if (sorter === 'CampaignCode') {
          sorter = 'code';
        } else {
          sorter = sorter.toLowerCase();
        }
        this.sortBy = sorter + sortDir;
        this.loadTable = true;
        this.retrieveReferralCampaigns();
      }
    }
  },
  mounted() {
    this.retrieveReferralCampaigns();
    this.getBrands();
  }
};
</script>