import Vue from "vue";
import Vuex from "vuex";
import UserInfoApi from "../app/user-info-api"
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    cognitoInfo: {},
    loggedIn: false,
    groups: {},
    admin: false,
    resAdmin: false,
    resAgent: false,
    lastPage: ''
  },
  mutations: {
    setLoggedIn(state, newValue) {
      state.loggedIn = newValue;
    },
    setLoggedOut(state) {
      state.loggedIn = false;
      state.cognitoInfo = {};
      state.groups = {};
      state.admin = false;
      state.resAdmin = false;
      state.resAgent = false;
    },
    setCognitoInfo(state, newValue) {
      state.cognitoInfo = newValue;
    },
    setGroupsInfo(state, newValue) {
      state.groups = newValue;
      let env = process.env.VUE_APP_ENV;
      if (process.env.VUE_APP_ENV == 'DEV') {
        env = 'UAT';
      }
      let adminString = new RegExp(`^Admin-${env}`);
      let resAdminString = new RegExp(`^ReservationsAdmin-${env}`);
      let envRoles = state.groups.filter(s => s.includes(env));
      if (env == 'PROD' && envRoles == '') {
        let userName = store.getters.cognitoInfo.username;
        localStorage.setItem('reloaded', '1');
        if (userName != undefined) {
          UserInfoApi.defaultRole(userName);
        }
        envRoles = ["ReservationsAgent-PROD"];
      }
      state.groups = envRoles;
      state.admin = envRoles.some(role => adminString.test(role));
      state.resAdmin = envRoles.some(role => resAdminString.test(role));
      state.resAgent = envRoles.some(role => ('ReservationsAgent-' + env).indexOf(role) >= 0) &&
        !envRoles.some(role => resAdminString.test(role)) &&
        !envRoles.some(role => adminString.test(role));
    },
    setLastPage(state, newValue) {
      state.lastPage = newValue;
    }
  },
  getters: {
    loggedIn: (state) => state.loggedIn,
    cognitoInfo: (state) => state.cognitoInfo,
    groups: (state) => state.groups,
    admin: (state) => state.admin,
    resAdmin: (state) => state.resAdmin,
    resAgent: (state) => state.resAgent,
    lastPage: (state) => state.lastPage
  },
});
export default store;