import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class RegionDataService {
  getAll(params) {
    return http.get(`/Regions/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&sortBy=${params.sortBy}&query=${params.query}`);
  }

  get(code) {
    return http.get(`/Regions/item/${code}`);
  }

  create(data) {
    return http.post("/Regions/item/", data);
  }

  update(data) {
    return http.put(`/Regions/item/`, data);
  }

  delete(code) {
    return http.delete(`/Regions/item/${code}`);
  }

  find(params) {
    return http.get(`/Regions/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&sortBy=${params.sortBy}&query=${params.query}`);
  }
}

export default new RegionDataService();