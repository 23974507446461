<template>
  <div v-if="currentReferralCampaign" class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Edit Referral Campaign</p>
    <Alert :resp="message" />
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" md="6" class="pb-0">
          <v-text-field
            v-model="currentReferralCampaign.code"
            :rules="[(v) => !!v || 'Campaign Code is required']"
            label="Campaign Code"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pb-0 pt-sm-0 pt-md-3">
          <v-text-field
            v-model="currentReferralCampaign.campaignName"
            :rules="[(v) => !!v || 'Campaign Name is required']"
            label="Campaign Name"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <Datepicker @val="datepickerValue" :val.sync="this.currentReferralCampaign.expirationDate" label="Expiration Date" validation="Expiration Date is required" :minimum="nowDate" />
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field
            type="number"
            v-model="currentReferralCampaign.creditAmount"
            label="Credit Amount"
            required
            :min="0"
        ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field
            type="number"
            v-model="currentReferralCampaign.discountAmount"
             label="Discount Amount"
            required
            :min="0"
            ></v-text-field>
        </v-col> 
        
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <Select v-model="currentReferralCampaign.sellingCompany_Code" :val="currentReferralCampaign.sellingCompany_Code" :selectItems="dropdownItems" label="Selling Company" />
        </v-col>
        <v-col cols="12" md="6">
          <BackButton />
          <v-btn color="error" class="mr-2" @click="$refs.modal.dialog = true">
            Delete
          </v-btn>
          <v-btn color="primary" @click="updateReferralCampaign">
            Update
          </v-btn>
          <Dialog text="Referral Campaign" verb="delete" ref="modal" />
        </v-col>
      </v-row>
    </v-form>
  </div>

  <div v-else-if="deletedCampaign" class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Edit Referral Campaign</p>
    <Alert :resp="message" />
  </div>

  <div v-else class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <Loading text="Referral Campaign" />
  </div>
</template>

<script>
import ReferralCampaignService from "../../services/ReferralCampaignDataService";
import SellingCompanyService from "../../services/SellingCompanyDataService";
import BackButton from "@/components/Common/BackButton.vue";
import Loading from "@/components/Common/Loading.vue";
import Dialog from '@/components/Common/Dialog.vue';
import Datepicker from "@/components/Common/Datepicker.vue";
import Alert from '@/components/Common/Alert.vue';
import Select from '@/components/Common/Select.vue';
export default {
  name: "UpdateReferralCampaign",
  data() {
      return {
      nowDate: new Date().toISOString().slice(0, 10),
      currentReferralCampaign: null,
      deletedCampaign: false,
      message: {
        text: "",
        type: ""
      },
      dropdownItems: [],
      params : {
        query : "",
        pageIndex : 0,
        pageSize : 100,
        sortBy : "date_asc",
        brand : "*"
      }
    };
  },
  methods: {
    getSellingCompanies() {
      SellingCompanyService.getAll(this.params)
        .then((response) => {
          this.dropdownItems = response.data.data.map(function(v){return v.code;});
          console.log(this.dropdownItems);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getReferralCampaign(id) {
      ReferralCampaignService.get(id)
        .then((response) => {
          this.currentReferralCampaign = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateReferralCampaign() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == false) return;
      ReferralCampaignService.update(this.currentReferralCampaign)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The ReferralCampaign was updated successfully!";
          this.message.type = "success";
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    dialogData() {
      ReferralCampaignService.delete(this.currentReferralCampaign.id)
        .then((response) => {
          console.log(response.data);
          this.message.text = "The ReferralCampaign was deleted successfully!";
          this.message.type = "success";
          this.currentReferralCampaign = null;
          this.deletedCampaign = true;
        })
        .catch((e) => {
          console.log(e);
          this.message.text = e;
          this.message.type = "error";
        });
    },
    datepickerValue(value) {
      this.currentReferralCampaign.expirationDate = value;
    }
  },
  mounted() {
    this.getReferralCampaign(this.$route.params.id);
    this.getSellingCompanies();
  },
  components: {
    BackButton,
    Loading,
    Dialog,
    Datepicker,
    Alert,
    Select
  }
};
</script>