<template>
  <div v-if="currentCredits" class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <p class="headline">Edit Credit</p>
    <Alert :resp="message" />
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <h3>Credit Data</h3>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field v-model="currentCredits.createdDate"
            label="Creation Date"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field v-model="currentCredits.redemptionDate"
            label="Redemption Date"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field v-model="currentCredits.expirationDate"
            label="Expiration Date"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <Select v-model="currentCredits.status" :val="currentCredits.status" :selectItems="dropdownItems" label="Status" />
          <a @click="resetSelect" class="float-right text-caption" v-if="initialSelect != currentCredits.status">
            Reset Status
            <v-icon
              color="primary"
              size="18"
            >
              mdi-undo-variant
            </v-icon>
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Credit Redemption</h3>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field type="number"
            v-model="currentCredits.amount"
            label="Credit Amount"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field type="number"
            v-model="currentCredits.redeemed"
            label="Credits Redeemed"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-subheader class="pa-0 pb-8">Credits to Redeem</v-subheader>
          <v-slider
            v-model="sliderValue"
            track-color="grey"
            :rules="currentCredits.status == 'PartiallyRedeemed' ? [(v) => !!v || 'Credits to Redeem is required'] : []"
            min="0"
            :max="currentCredits.amount - currentCredits.redeemed"
            :disabled="disableForm"
            @input="changeRedeem"
            :thumb-size="24"
            thumb-label="always"
          >
            <template v-slot:prepend>
              <v-icon
                @click="decrement"
              >
                mdi-minus
              </v-icon>
            </template>
            <template v-slot:append>
              <v-icon
                @click="increment"
              >
                mdi-plus
              </v-icon>
            </template>
          </v-slider>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-checkbox
            v-model="checkAll"
            label="Redeem All Credits"
            @change="checkboxData"
            :disabled="disableForm"
          ></v-checkbox>
        </v-col>
        <v-col cols="12">
          <BackButton />
          <v-btn color="primary" @click="updateCredits">
            Update
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>

  <div v-else class="col-sm-8 col-md-10 col-lg-8 mx-auto">
    <Loading text="Credit" />
  </div>
</template>

<script>
import ReferralCreditService from "../../services/ReferralCreditDataService";
import BackButton from "@/components/Common/BackButton.vue";
import Loading from "@/components/Common/Loading.vue";
import Alert from '@/components/Common/Alert.vue';
import Select from '@/components/Common/Select.vue';
export default {
  name: "UpdateCredits",
  data() {
    return {
      currentCredits: null,
      checkAll: false,
      message: {
        text: "",
        type: ""
      },
      dropdownItems: [
        {
          text: 'Created'
        },
        {
          text: 'Expired'
        },
        {
          text: 'Deleted'
        },
        {
          text: 'Redeemed'
        },
        {
          text: 'PartiallyRedeemed'
        },
        {
          text: 'Cancelled'
        }
      ],
      params : {
        query : "",
        pageIndex : 0,
        pageSize : 100,
        sortBy : "date_asc"
      },
      disableForm: false,
      initialSelect: null,
      sliderValue: 0
    };
  },
  methods: {
    getCredits(id) {
      ReferralCreditService.get(id)
        .then((response) => {
          this.currentCredits = response.data;
          this.sliderValue = this.currentCredits.toRedeem = 0;
          this.initialSelect = this.currentCredits.status;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateCredits() {
      this.message.text = "";
      this.message.type = "";
      this.$refs.form.validate();
      if (this.$refs.form.validate() == false) return;
      if (this.currentCredits.status == 'PartiallyRedeemed' || this.currentCredits.status == 'Redeemed') {
        ReferralCreditService.partialredeem(this.currentCredits)
          .then((response) => {
            console.log(response.data);
            this.updateCreditsResult();
          })
          .catch((e) => {
            console.log(e);
            this.message.text = e;
            this.message.type = "error";
          });
      } else {
        ReferralCreditService.update(this.currentCredits)
          .then((response) => {
            console.log(response.data);
            this.updateCreditsResult();
          })
          .catch((e) => {
            console.log(e);
            this.message.text = e;
            this.message.type = "error";
          });
      }
    },
    updateCreditsResult() {
      this.message.text = "The Credit was redeemed successfully!";
      this.message.type = "success";
      this.currentCredits.redeemed = this.currentCredits.redeemed + +this.currentCredits.toRedeem;
      this.sliderValue = this.currentCredits.toRedeem = 0;
      this.initialSelect = this.currentCredits.status;
      this.checkRedeemed();
      this.$refs.form.resetValidation();
    },
    checkboxData(e) {
      this.currentCredits.status = e == true ? 'Redeemed' : this.currentCredits.redeemed > 0 ? 'PartiallyRedeemed' : 'Created';
    },
    changeRedeem(value) {
      this.changeStatus(value);
      this.currentCredits.toRedeem = value;
    },
    resetSelect() {
      this.currentCredits.status = this.initialSelect;
    },
    decrement () {
      this.sliderValue -= this.currentCredits.amount - this.currentCredits.redeemed > 50 ? 50 : 1;
      this.currentCredits.toRedeem = this.sliderValue;
      this.changeStatus(this.sliderValue);
    },
    increment () {
      this.sliderValue += this.currentCredits.amount - this.currentCredits.redeemed > 50 ? 50 : 1;
      this.currentCredits.toRedeem = this.sliderValue;
      this.changeStatus(this.sliderValue);
    },
    changeStatus(val) {
      if (val == this.currentCredits.amount - this.currentCredits.redeemed) {
        this.currentCredits.status = 'Redeemed';
      } else if (val == 0) {
        this.currentCredits.status = this.initialSelect == 'Created' ? 'Created' : 'PartiallyRedeemed';
      } else {
        this.currentCredits.status = 'PartiallyRedeemed';
      }
    },
    enableOptions(bool) {
      for (var i in this.dropdownItems) {
        this.dropdownItems[i].disabled = bool;
      }
    },
    checkRedeemed() {
      if (this.initialSelect == 'Redeemed') {
        this.disableForm = true;
        this.dropdownItems[4].disabled = true; // disable PartiallyRedeemed
      }
    }
  },
  components: {
    BackButton,
    Loading,
    Alert,
    Select
  },
  watch: {
    'currentCredits.status': function(value) {
      this.disableForm = false;
      if (value == 'Created') {
        this.checkAll = false;
        this.sliderValue = this.currentCredits.toRedeem = 0;
        this.enableOptions(false);
      } else if (value == 'Redeemed') {
        this.checkAll = true;
        this.sliderValue = this.currentCredits.toRedeem = this.currentCredits.amount - this.currentCredits.redeemed;
        this.enableOptions(false);
        this.dropdownItems[0].disabled = true; // disable Created
        this.checkRedeemed();
      } else if (value == 'PartiallyRedeemed') {
        this.checkAll = false;
        if (this.sliderValue == this.currentCredits.amount - this.currentCredits.redeemed) {
          this.sliderValue = this.currentCredits.toRedeem = 0;
        }
        this.enableOptions(false);
        this.dropdownItems[0].disabled = true; // disable Created
      } else {
        this.enableOptions(true);
        if (value == 'Expired') {
          this.dropdownItems[1].disabled = false; // enable Expired
        } else if (value == 'Deleted') {
          this.dropdownItems[2].disabled = false; // enable Deleted
        } else if (value == 'Cancelled') {
          this.dropdownItems[5].disabled = false; // enable Cancelled
        }
        this.checkAll = false;
        this.disableForm = true;
        this.sliderValue = this.currentCredits.toRedeem = 0;
      }
    }
  },
  mounted() {
    this.getCredits(this.$route.params.id);
  }
};
</script>