<template>
  <v-row align="center">
    <v-col cols="12" class="mt-3 pb-0"> 
      <h1>Referral Advocates</h1>
    </v-col>
    <Switcher toggleOne="All Advocates" toggleTwo="Travel Advisor Advocates" v-if="!$store.getters.resAgent" />
    <Search />
    <v-col cols="12" class="pt-0">
      <v-data-table
        :headers="headers"
        :items="ReferralAdvocates"
        disable-pagination
        class="elevation-2"
        :loading="loadTable"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :server-items-length="count"
        :hide-default-footer="true"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="!$store.getters.resAgent" small class="mr-2" @click="editReferralAdvocate(item.Id)">mdi-pencil</v-icon>
          <v-icon v-if="$store.getters.resAgent" small class="mr-2" @click="viewReferralAdvocate(item.Id)">mdi-eye-outline</v-icon>
        </template>
      </v-data-table>
    </v-col>
    <Pagination :count="count" :totalPages="totalPages" :pageSize="pageSize" :pageIndex="pageIndex" />
  </v-row>
</template>

<script>
import ReferralAdvocateService from "../../services/ReferralAdvocateDataService";
import Search from '@/components/Common/Search.vue';
import Pagination from '@/components/Common/Pagination.vue';
import Switcher from '@/components/Common/Switcher.vue';
export default {
  name: "ListReferralAdvocates",
  data() {
    return {
      ReferralAdvocates: [],
      headers: [
        { text: "Advocate Id", value: "Id", sortable: false },
        { text: "Name", value: "Name" },
        { text: "Email", value: "EmailAddress" },
        { text: "Salesforce Contact Id", value: "SalesforceContactId", sortable: false },
        { text: "Completed Referrals", value: "CompletedReferrals"},
        { text: "Created Date", value: "CreatedDate" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      query: "",
      pageIndex: 1,
      totalPages: 0,
      pageSize: 10,
      count: 0,
      sortBy: "date_dsc",
      role: "",
      loadTable: true,
      options: {}
    };
  },
  methods: {
    getRequestParams(query, pageIndex, pageSize, sortBy, role) {
      let params = {};     
      params["query"] = query;
      if (pageIndex) {
        params["pageIndex"] = pageIndex - 1;
      }
      if (pageSize) {
        params["pageSize"] = pageSize;
      }  
      if (sortBy) {
        params["sortBy"] = sortBy;
      }
      params["role"] = role;
      return params;
    },
    retrieveReferralAdvocates() {
      const params = this.getRequestParams(
        this.query,
        this.pageIndex,
        this.pageSize,
        this.sortBy,
        this.role
      );
      ReferralAdvocateService.getAll(params)
      .then((response) => {
        this.ReferralAdvocates = response.data.data.map(this.getDisplayReferralAdvocate);
        this.totalPages = Math.ceil(response.data.count/response.data.pageSize,1);
        this.count = response.data.count;
        this.loadTable = false;
      })
      .catch((e) => {
        console.log(e);
      });
    },
    refreshList() {
      this.retrieveReferralAdvocates();
    },
    editReferralAdvocate(id) {
      this.$router.push({ name: "UpdateReferralAdvocate", params: { id: id } });
    },
    viewReferralAdvocate(id) {
      this.$router.push({ name: "ViewReferralAdvocate", params: { id: id } });
    },
    getDisplayReferralAdvocate(ReferralAdvocate) {
      return {
        Id: ReferralAdvocate.id,
        Name: ReferralAdvocate.user.firstName + " "+ ReferralAdvocate.user.lastName ,
        EmailAddress: ReferralAdvocate.user.emailAddress,
        SalesforceContactId: ReferralAdvocate.salesforceContactId,
        CompletedReferrals: ReferralAdvocate.completedReferrals,
        CreatedDate: ReferralAdvocate.createdDate
      };
    }
  },
  components: {
    Search,
    Pagination,
    Switcher
  },
  watch: {
    options: {
      handler () {
        let sorter = this.options.sortBy[0];
        let sortDir = this.options.sortDesc[0] ? '_dsc' : '_asc';
        if (sorter == undefined) {
          return;
        } else if (sorter === 'CreatedDate') {
          sorter = 'date';
        } else if (sorter === 'EmailAddress') {
          sorter = 'email';
        } else if (sorter === 'CompletedReferrals') {
            sorter = 'referrals';
        } else {
          sorter = sorter.toLowerCase();
        }
        this.sortBy = sorter + sortDir;
        this.loadTable = true;
        this.retrieveReferralAdvocates();
      }
    },
  },
  mounted() {
    this.retrieveReferralAdvocates();
  },
};
</script>