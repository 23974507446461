<template>
  <Alert :resp="message" />
</template>

<script>
import Alert from '@/components/Common/Alert.vue';
export default {
  data: function(){
    return { 
      message: {
        text: "",
        type: "error"
      }
    }
  },
  mounted(){
    this.message = this.$route.query.message;
  },
  components: {
    Alert
  }
}
</script>