<template>
  <v-btn color="secondary" class="mr-2" v-on:click.prevent="goBack">
    Back
  </v-btn>
</template>

<script>

export default {
  name: "BackButton",
  methods: {
    goBack() {
      if (window.history.length > 1) {
        this.$router.push({ path: this.$store.getters.lastPage });
      } else {
        this.$router.push('/');
      }
    }
  }
};
</script>