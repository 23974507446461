import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class SellingCompanyDataService {
  getAll(params) {
    return http.get(`/SellingCompanies/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&brand=${params.brand}&sortBy=${params.sortBy}&query=${params.query}`);
  }

  get(code) {
    return http.get(`/SellingCompanies/item/${code}`);
  }

  create(data) {
    return http.post("/SellingCompanies/item/", data);
  }

  update(data) {
    return http.put(`/SellingCompanies/item/`, data);
  }

  delete(code) {
    return http.delete(`/SellingCompanies/item/${code}`);
  }

  find(params) {
    return http.get(`/SellingCompanies/items?pageSize=${params.pageSize}&pageIndex=${params.pageIndex}&brand=${params.brand}&sortBy=${params.sortBy}&query=${params.query}`);
  }
}

export default new SellingCompanyDataService();