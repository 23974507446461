/* eslint-disable */
import {CognitoAuth, StorageHelper} from 'amazon-cognito-auth-js';
import IndexRouter from '../router/';
import UserInfoApi from './user-info-api';
import Store from "../store/";


const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
const REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI;
const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
const REDIRECT_URI_SIGNOUT = process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;

var authData = {
    ClientId : CLIENT_ID,
    AppWebDomain : APP_DOMAIN,
    TokenScopesArray : ['openid', 'email'],
    RedirectUriSignIn : REDIRECT_URI,
    RedirectUriSignOut : REDIRECT_URI_SIGNOUT,
    UserPoolId : USERPOOL_ID    
}

var auth = new CognitoAuth(authData);
auth.userhandler = {
    onSuccess: async function(result) {
        localStorage.removeItem('reloaded');
        Store.commit("setLoggedIn", true);
        UserInfoApi.getUserInfo().then(response => {
            Store.commit('setCognitoInfo', response.data);
            let userName = Store.getters.cognitoInfo.username;
            if (!Store.getters.groups.length && (userName != undefined)) {
                Store.commit('setGroupsInfo', UserInfoApi.getGroups());
            }
            IndexRouter.push('/');
        });
    },
    onFailure: function(err) {
        Store.commit("setLoggedOut");
        IndexRouter.go({ path: '/error', query: { message: 'Login failed due to ' + err } });
    }
};

function getUserInfoStorageKey(){
    var keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId();
    var tokenUserName = auth.signInUserSession.getAccessToken().getUsername();
    var userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
    return userInfoKey;
}

var storageHelper = new StorageHelper();
var storage = storageHelper.getStorage();
export default{
    auth: auth,
    login(){
        auth.getSession();
    },
    logout(){
        if (auth.isUserSignedIn()) {
            var userInfoKey = this.getUserInfoStorageKey();
            localStorage.removeItem('reloaded');
            auth.signOut();
            storage.removeItem(userInfoKey);
        }
    },
    getUserInfoStorageKey
}