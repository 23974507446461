import http from "@/http/http-common";
import interceptor from "@/http/http-common-interceptor";

interceptor.setInterceptor();

class ReferralFileService {
  uploadCsv(file) {
    let formData = new FormData();
    formData.append("file", file);
    return http.post("/ReferralFile/UploadReferrals", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
}

export default new ReferralFileService();
